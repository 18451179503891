"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Styles = exports.UnvalidatedStyles = void 0;
var react_native_1 = require("react-native");
var styles_1 = require("@shared/styles");
/*
TODO the answer to the iOS chat input misalignment lies in here
*/
exports.UnvalidatedStyles = {
    input: {
        height: 40,
        backgroundColor: 'transparent',
    },
    // We have slightly different heights on web and native.
    // On web we need to be as tall as the old chat keyboard
    // (which might be next to the new eg in chat/task details)
    inputPaddingNormal: 12,
    inputPaddingLarge: 20,
    border: {
        borderRadius: 6,
        borderColor: styles_1.Colors.gray.light,
        borderWidth: 1,
    },
};
exports.Styles = react_native_1.StyleSheet.create({
    default: {
        alignSelf: 'stretch',
    },
    border: {
        borderRadius: 6,
        borderColor: styles_1.Colors.gray.light,
        borderWidth: 1,
    },
    focusedBorder: {
        borderColor: styles_1.Colors.v2.green30,
    },
    padding: __assign(__assign({}, react_native_1.Platform.select({
        ios: {
            paddingVertical: 20,
        },
        android: {
            paddingVertical: 15,
        },
        web: {
            paddingVertical: 10,
        },
    })), react_native_1.Platform.select({
        web: {
            paddingHorizontal: 10,
        },
        default: {
            paddingHorizontal: 20,
        },
    })),
    removeAndroidDefaultPadding: {
        padding: 0,
    },
});
