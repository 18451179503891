"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormErrors = useFormErrors;
var lodash_1 = require("lodash");
var react_1 = require("react");
var i18n_1 = require("@shared/util/i18n");
function useFormErrors(_a) {
    var _b;
    var apiErrors = _a.apiErrors, fields = _a.fields, components = _a.components, _c = _a.includeRequiredErrors, includeRequiredErrors = _c === void 0 ? true : _c;
    var requiredErrors = useRequiredErrors({ fields: fields, components: components });
    var localValidationErrors = useLocalValidationErrors({ fields: fields });
    var serverErrors = (_b = apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.map(genericizeApiError)) !== null && _b !== void 0 ? _b : [];
    var localErrors = __spreadArray(__spreadArray([], requiredErrors, true), localValidationErrors, true);
    var allErrors = __spreadArray(__spreadArray([], localErrors, true), serverErrors, true);
    return {
        errors: includeRequiredErrors ? allErrors : __spreadArray(__spreadArray([], localValidationErrors, true), serverErrors, true),
        isValid: (0, lodash_1.isEmpty)(localErrors),
    };
}
function useRequiredErrors(_a) {
    var fields = _a.fields, components = _a.components;
    return (0, react_1.useMemo)(function () {
        return components
            .filter(function (component) { return 'required' in component && component.required; })
            .filter(function (component) { return (0, lodash_1.isNil)(fields === null || fields === void 0 ? void 0 : fields[component.id]); })
            .map(function (component) { return ({
            description: (0, i18n_1.i18n)('card.form.errors.required'),
            componentId: component.id,
        }); });
    }, [components, fields]);
}
function useLocalValidationErrors(_a) {
    var fields = _a.fields;
    return (0, react_1.useMemo)(function () {
        return Object.keys(fields !== null && fields !== void 0 ? fields : {})
            .filter(function (componentId) { var _a; return !(0, lodash_1.isNil)((_a = fields === null || fields === void 0 ? void 0 : fields[componentId]) === null || _a === void 0 ? void 0 : _a.validate()); })
            .map(function (componentId) {
            var _a, _b;
            return ({
                description: (_b = (_a = fields === null || fields === void 0 ? void 0 : fields[componentId]) === null || _a === void 0 ? void 0 : _a.validate()) !== null && _b !== void 0 ? _b : '',
                componentId: componentId,
            });
        });
    }, [fields]);
}
// TODO server errors not currently usable in UI, so
// using generic error message for now.
// Eventually shape should include applicable componentId.
// when returned error (see useSaveNewCardEntity) is available,
// can use that directly and remove this mapping.
function genericizeApiError() {
    return {
        description: (0, i18n_1.i18n)('card.form.errors.generic'),
        // componentId,
    };
}
