"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddNewEntityToRelatedEntityField = useAddNewEntityToRelatedEntityField;
var lodash_1 = require("lodash");
var react_1 = require("react");
var TextField_1 = require("@shared/data/entity/fields/TextField");
var useDefaultFields_1 = require("@shared/scenes/entity/create/hooks/useDefaultFields");
var useSaveNewCardEntity_1 = require("@shared/scenes/entity/create/hooks/useSaveNewCardEntity");
function useAddNewEntityToRelatedEntityField(_a) {
    var _this = this;
    var workflowTemplateId = _a.workflowTemplateId, field = _a.field, _b = _a.disabled, disabled = _b === void 0 ? false : _b, onCreate = _a.onCreate;
    var saveEntity = (0, useSaveNewCardEntity_1.useSaveNewEntity)(workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : undefined).saveEntity;
    var defaultFields = (0, useDefaultFields_1.useDefaultFields)({ workflowTemplateId: workflowTemplateId });
    return (0, react_1.useCallback)(function (name) { return __awaiter(_this, void 0, void 0, function () {
        var result, newEntity, newRelatedEntity, oldEntities, _a;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (disabled) {
                        return [2 /*return*/, null];
                    }
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, saveEntity(__assign({ name: new TextField_1.TextField(name) }, defaultFields))];
                case 2:
                    result = _e.sent();
                    newEntity = (_c = (_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.createWorkflowEntity) === null || _c === void 0 ? void 0 : _c.entity;
                    if (!(0, lodash_1.isNil)(newEntity)) {
                        newRelatedEntity = {
                            id: newEntity.id,
                            fields: newEntity.fields,
                            workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '',
                            createdAt: '',
                            updatedAt: '',
                            threadId: '',
                        };
                        oldEntities = (_d = field === null || field === void 0 ? void 0 : field.relatedEntities) !== null && _d !== void 0 ? _d : [];
                        onCreate(__spreadArray(__spreadArray([], oldEntities, true), [newRelatedEntity], false));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    _a = _e.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [disabled, workflowTemplateId, field === null || field === void 0 ? void 0 : field.relatedEntities, onCreate, saveEntity, defaultFields]);
}
