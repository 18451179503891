"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TodoEditField = TodoEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var animation_1 = require("@shared/components/animation");
var hooks_1 = require("@shared/components/blocks/lib/inputTodo/hooks");
var TodoField_1 = require("@shared/data/entity/fields/TodoField");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var useWorkspaceDetails_1 = require("@shared/scenes/workflows/hooks/useWorkspaceDetails");
var styles_1 = require("@shared/styles");
var i18n_1 = require("@shared/util/i18n");
var hooks_2 = require("@shared/util/permissions/hooks");
var uuid = __importStar(require("@shared/util/uuid"));
var EditFieldContainer_1 = require("./EditFieldContainer");
function TodoEditField(_a) {
    var _b, _c, _d;
    var field = _a.field, component = _a.component, workflowTemplateId = _a.workflowTemplateId, updateField = _a.updateField, error = _a.error;
    var _e = (0, react_1.useState)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _a !== void 0 ? _a : []; }), todos = _e[0], setTodos = _e[1];
    var _f = (0, react_1.useState)(createNewTodoItem), newTodo = _f[0], setNewTodo = _f[1];
    var _g = (0, react_1.useState)(false), initialValueSet = _g[0], setInitialValueSet = _g[1];
    var existingTodos = (0, react_1.useMemo)(function () { return todos.filter(function (todo) { return todo.id !== newTodo.id; }); }, [todos, newTodo.id]);
    var isWeb = react_native_1.Platform.OS === 'web';
    var workspaceDetails = (0, useWorkspaceDetails_1.useWorkspaceDetails)();
    var channelId = (_b = workspaceDetails.get(workflowTemplateId)) === null || _b === void 0 ? void 0 : _b.id;
    var updatePermission = (0, hooks_2.usePermission)({
        action: 'update',
        subject: 'Card',
        channelId: channelId,
    });
    var canUpdateStatus = !!updatePermission.enabled;
    var _h = (0, ComponentFocus_1.useComponentFocus)(), setComponentFocused = _h.setComponentFocused, setComponentBlurred = _h.setComponentBlurred;
    (0, react_1.useEffect)(function () {
        // update the field when todos change
        var updatedTodos = todos.map(trimTodoSummary);
        updateField(component.id, new TodoField_1.TodoField(updatedTodos));
    }, [todos, component.id, updateField]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (initialValueSet) {
            return;
        }
        if (!(0, lodash_1.isEmpty)(field === null || field === void 0 ? void 0 : field.jsonBlob) && !(0, lodash_1.isEqual)(field === null || field === void 0 ? void 0 : field.jsonBlob, todos)) {
            setInitialValueSet(true);
            setTodos((_a = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _a !== void 0 ? _a : []);
        }
    }, [field, todos, initialValueSet]);
    (0, react_1.useEffect)(function () {
        // Add or remove the new todo when it changes. If it's empty, remove it if
        // it was previously added. If it's not empty, add it or update it.
        setTodos(function (prevTodos) {
            var newTodoIsEmpty = (0, lodash_1.isEmpty)(newTodo.summary.trim());
            var newTodoIsIncluded = prevTodos.some(function (todo) { return todo.id === newTodo.id; });
            if (newTodoIsIncluded && newTodoIsEmpty) {
                return prevTodos.filter(function (todo) { return todo.id !== newTodo.id; });
            }
            if (newTodoIsIncluded && !newTodoIsEmpty) {
                return prevTodos.map(function (todo) { return (todo.id === newTodo.id ? newTodo : todo); });
            }
            if (!newTodoIsIncluded && !newTodoIsEmpty) {
                return __spreadArray(__spreadArray([], prevTodos, true), [newTodo], false);
            }
            return prevTodos;
        });
    }, [newTodo]);
    var scrollIntoView = (0, hooks_1.useScrollTextInputIntoView)();
    var debouncedScrollIntoView = (0, hooks_1.useDebouncedScrollTextInputIntoView)();
    var handleFocus = (0, react_1.useCallback)(function () {
        if (isWeb) {
            scrollIntoView();
        }
        else {
            debouncedScrollIntoView();
        }
        setComponentFocused();
    }, [isWeb, scrollIntoView, debouncedScrollIntoView, setComponentFocused]);
    var handleBlur = (0, react_1.useCallback)(function () {
        setComponentBlurred();
    }, [setComponentBlurred]);
    var updateTodo = (0, react_1.useCallback)(function (updatedItem) {
        setTodos(function (prevTodos) {
            return prevTodos.map(function (todo) {
                return todo.id === updatedItem.id ? __assign(__assign({}, todo), updatedItem) : todo;
            });
        });
    }, []);
    var removeTodo = (0, react_1.useCallback)(function (id) {
        setTodos(function (prevTodos) { return prevTodos.filter(function (todo) { return todo.id !== id; }); });
    }, []);
    var updateNewTodo = (0, react_1.useCallback)(function (updatedItem) {
        setNewTodo(function (prev) { return (__assign(__assign({}, prev), updatedItem)); });
    }, []);
    var clearNewTodo = (0, react_1.useCallback)(function () {
        setNewTodo(function (prev) { return (__assign(__assign({}, prev), { summary: '' })); });
    }, []);
    var resetNewTodo = (0, react_1.useCallback)(function () {
        setNewTodo(createNewTodoItem());
        // need to re-clear the new todo because react-native text input holds on
        // to its previous value
        requestAnimationFrame(function () { return setNewTodo(function (prev) { return (__assign(__assign({}, prev), { summary: '' })); }); });
    }, []);
    var onDone = (0, react_1.useCallback)(function () {
        var isNewTodoEmpty = (0, lodash_1.isEmpty)(newTodo.summary.trim());
        if (isNewTodoEmpty) {
            react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss();
        }
        else {
            resetNewTodo();
        }
    }, [newTodo.summary, resetNewTodo]);
    var onPaste = (0, react_1.useCallback)(function (pastedText) {
        var pastedTodos = getPastedTodos(pastedText);
        if (pastedTodos.length < 2) {
            return true;
        }
        setTodos(function (prevTodos) { return __spreadArray(__spreadArray([], prevTodos, true), pastedTodos, true); });
        resetNewTodo();
        requestAnimationFrame(scrollIntoView);
        return false;
    }, [resetNewTodo, scrollIntoView]);
    var label = getComponentLabel(component, (_c = field === null || field === void 0 ? void 0 : field.jsonBlob) !== null && _c !== void 0 ? _c : []);
    var placeholder = (_d = component.placeholder) !== null && _d !== void 0 ? _d : (0, i18n_1.i18n)('card.blocks.todoInput.addPlaceholder');
    var hasItems = !(0, lodash_1.isEmpty)(field === null || field === void 0 ? void 0 : field.jsonBlob);
    var isReadOnly = !!component.readonly;
    if (isReadOnly && !hasItems) {
        return null;
    }
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, label: label, indentLabel: true, showLabel: hasItems, showIcon: false, error: error },
        react_1.default.createElement(components_1.MBView, { style: { marginTop: -(0, components_1.grid)(0.5) } },
            existingTodos.map(function (todoItem) { return (react_1.default.createElement(TodoItemField, { key: todoItem.id, todoItem: todoItem, updateTodo: updateTodo, removeTodo: removeTodo, placeholder: placeholder, isReadOnly: isReadOnly, canUpdateStatus: canUpdateStatus, onDone: react_native_1.Keyboard === null || react_native_1.Keyboard === void 0 ? void 0 : react_native_1.Keyboard.dismiss, onFocus: handleFocus, onBlur: handleBlur, onContentSizeChange: debouncedScrollIntoView, error: error })); }),
            !isReadOnly && (react_1.default.createElement(TodoItemField, { isNew: true, todoItem: newTodo, updateTodo: updateNewTodo, removeTodo: clearNewTodo, placeholder: placeholder, isReadOnly: isReadOnly, onPaste: onPaste, onDone: onDone, onFocus: handleFocus, onBlur: handleBlur, onContentSizeChange: debouncedScrollIntoView, error: error })))));
}
function TodoItemField(_a) {
    var todoItem = _a.todoItem, placeholder = _a.placeholder, _b = _a.isNew, isNew = _b === void 0 ? false : _b, _c = _a.isReadOnly, isReadOnly = _c === void 0 ? false : _c, _d = _a.canUpdateStatus, canUpdateStatus = _d === void 0 ? true : _d, updateTodo = _a.updateTodo, removeTodo = _a.removeTodo, onPaste = _a.onPaste, onDone = _a.onDone, onFocus = _a.onFocus, onBlur = _a.onBlur, onContentSizeChange = _a.onContentSizeChange, error = _a.error;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var id = todoItem.id, summary = todoItem.summary;
    var setSummary = (0, react_1.useCallback)(function (text) { return updateTodo({ id: id, summary: text }); }, [id, updateTodo]);
    var markComplete = (0, react_1.useCallback)(function () {
        updateTodo({ id: id, status: 'COMPLETED' });
    }, [id, updateTodo]);
    var markIncomplete = (0, react_1.useCallback)(function () {
        updateTodo({ id: id, status: 'NEEDS-ACTION' });
    }, [id, updateTodo]);
    var onClear = (0, react_1.useCallback)(function () {
        removeTodo(id);
    }, [id, removeTodo]);
    var onKeyPress = (0, react_1.useCallback)(function (e) {
        if (e.nativeEvent.key === 'Enter') {
            onDone === null || onDone === void 0 ? void 0 : onDone();
        }
    }, [onDone]);
    var isWeb = react_native_1.Platform.OS === 'web';
    var isAndroid = react_native_1.Platform.OS === 'android';
    var isComplete = !isNew && isCompleted(todoItem);
    var hasValue = !(0, lodash_1.isEmpty)(summary.trim());
    var showClearButton = hasValue && !isReadOnly;
    return (react_1.default.createElement(components_1.MBView, { row: true },
        isNew && (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !hasValue, onPress: onDone },
            react_1.default.createElement(components_1.MBView, { paddingRight: 2 },
                react_1.default.createElement(components_1.MBIconV2, { name: "plus-circle", color: !(0, lodash_1.isEmpty)(error) ? colors.error.foreground : colors.accent })))),
        !isNew && isComplete && (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !canUpdateStatus, onPress: markIncomplete },
            react_1.default.createElement(components_1.MBView, { paddingRight: 2, style: { opacity: 0.7 } },
                react_1.default.createElement(animation_1.MBAnimationBounceIn, null,
                    react_1.default.createElement(components_1.MBIconV2, { name: "task-filled", color: colors.accent }))))),
        !isNew && !isComplete && (react_1.default.createElement(components_1.MBTouchableOpacity, { disabled: !canUpdateStatus, onPress: markComplete },
            react_1.default.createElement(components_1.MBView, { paddingRight: 2, style: { opacity: 0.7 } },
                react_1.default.createElement(components_1.MBIconV2, { name: "unfilled-circle", color: colors.accentInactive })))),
        isWeb && (react_1.default.createElement(components_1.MBAutosizeTextInput, { value: summary, onChangeText: setSummary, returnKeyType: "done", padding: "none", blurOnSubmit: !isNew, placeholder: placeholder, color: isComplete ? colors.foregroundInactive : colors.foregroundActive, onFocus: onFocus, onBlur: onBlur, onPaste: onPaste, onSubmitEditing: onDone, disabled: isReadOnly, style: {
                flex: 1,
                alignSelf: 'center',
                marginTop: 0,
                marginBottom: 0,
                textDecorationLine: isComplete ? 'line-through' : 'none',
            } })),
        !isWeb && (react_1.default.createElement(components_1.MBView, { style: { flex: 1, alignSelf: 'center' } },
            react_1.default.createElement(react_native_1.TextInput, { value: summary.replace(/\n+$/, ''), onChangeText: setSummary, returnKeyType: "done", blurOnSubmit: !isNew, textAlignVertical: 'center', placeholder: placeholder, placeholderTextColor: colors.foregroundInactive, onFocus: onFocus, onBlur: onBlur, onContentSizeChange: onContentSizeChange, onSubmitEditing: onDone, onKeyPress: isAndroid ? onKeyPress : undefined, multiline: true, editable: !isReadOnly, style: [
                    components_1.MBTypographyStyles.body1,
                    styles.nativeInput,
                    {
                        textDecorationLine: isComplete ? 'line-through' : 'none',
                        color: isComplete ? colors.foregroundInactive : colors.foregroundActive,
                    },
                ] }))),
        showClearButton && react_1.default.createElement(components_1.MBClearButton, { onPress: onClear })));
}
function getComponentLabel(component, todos) {
    var label = component.label;
    if ((0, lodash_1.isNil)(label)) {
        return undefined;
    }
    var total = todos.length;
    if (total === 0) {
        return label;
    }
    var completed = todos.filter(isCompleted).length;
    return "".concat(label, " (").concat(completed, "/").concat(total, ")");
}
function createNewTodoItem(summary) {
    if (summary === void 0) { summary = ''; }
    return {
        summary: summary,
        status: 'NEEDS-ACTION',
        id: uuid.v4(),
    };
}
function isCompleted(todoItem) {
    return todoItem.status === 'COMPLETED';
}
function trimTodoSummary(todoItem) {
    return __assign(__assign({}, todoItem), { summary: todoItem.summary.trim() });
}
function getPastedTodos(pastedText) {
    var pastedLines = pastedText
        .split('\n')
        .map(function (line) { return line.trim(); })
        .filter(function (line) { return !!line; });
    return pastedLines.map(createNewTodoItem);
}
var styles = react_native_1.StyleSheet.create({
    nativeInput: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        maxHeight: 100,
        marginTop: (0, components_1.grid)(0.75),
        marginBottom: (0, components_1.grid)(0.75),
    },
});
