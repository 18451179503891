"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardInputBlockBase = MBCardInputBlockBase;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var render_1 = require("@shared/components/blocks/contexts/render");
var styles_1 = require("@shared/styles");
var getTestId_1 = require("@shared/util/getTestId");
var utils_1 = require("./utils");
// This was measured as the minimum height of the input placeholder + label
// Prevents the vertically centered contents shifting when label is shown/hidden
var MIN_HEIGHT = 60;
function MBCardInputBlockBase(_a) {
    var block = _a.block, children = _a.children, renderLeft = _a.renderLeft, renderRight = _a.renderRight, onPress = _a.onPress, _b = _a.renderLabel, renderLabel = _b === void 0 ? utils_1.renderLabel : _b;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var renderContext = (0, react_1.useContext)(render_1.CardRenderContext);
    var errors = (0, react_1.useContext)(form_1.CardFormContext).errors;
    var error = errors[block.name];
    var left = (0, react_1.useMemo)(function () { return (renderLeft ? renderLeft() : (0, utils_1.renderIcon)(block.icon, block.name)); }, [block, renderLeft]);
    var right = (0, react_1.useMemo)(function () { return renderRight === null || renderRight === void 0 ? void 0 : renderRight(block); }, [block, renderRight]);
    var label = (0, react_1.useMemo)(function () { return renderLabel === null || renderLabel === void 0 ? void 0 : renderLabel(block); }, [block, renderLabel]);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onPress, testID: (0, getTestId_1.getTestId)('card-field-cards', block.name) },
        react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: 3, paddingVertical: 1, style: {
                minHeight: MIN_HEIGHT,
                backgroundColor: error ? colors.error.background : 'transparent',
            } },
            !!left && react_1.default.createElement(components_1.MBView, { paddingRight: renderContext === 'form' ? 2 : 1 }, left),
            react_1.default.createElement(components_1.MBView, { flex: true },
                label,
                children),
            right && react_1.default.createElement(components_1.MBView, { paddingLeft: 2 }, right)),
        (0, utils_1.renderError)(block)));
}
