"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.correctMissingBlockData = void 0;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var correctMissingBlockData = function (blocks, components) {
    var _a;
    return ((_a = blocks === null || blocks === void 0 ? void 0 : blocks.map(function (block) {
        var _a, _b, _c;
        if (!('name' in block)) {
            return block;
        }
        var component = components === null || components === void 0 ? void 0 : components.find(function (c) { return c.id === block.name; });
        if ((0, lodash_1.isNil)(component) || !(0, hooks_1.isInputComponent)(component)) {
            return block;
        }
        var newBlock = __assign({}, block);
        if ('icon' in newBlock && (0, lodash_1.isNull)(newBlock.icon)) {
            newBlock.icon = component.icon;
        }
        if ('label' in newBlock && (0, lodash_1.isNull)(newBlock.label)) {
            newBlock.label = (_a = component.label) !== null && _a !== void 0 ? _a : '';
        }
        if ('placeholder' in newBlock && (0, lodash_1.isNull)(newBlock.placeholder)) {
            newBlock.placeholder = (_b = component.placeholder) !== null && _b !== void 0 ? _b : '';
        }
        if ('tableHeader' in newBlock && (0, lodash_1.isNull)(newBlock.tableHeader)) {
            newBlock.tableHeader = (_c = component.label) !== null && _c !== void 0 ? _c : '';
        }
        return newBlock;
    })) !== null && _a !== void 0 ? _a : []);
};
exports.correctMissingBlockData = correctMissingBlockData;
