import * as React from 'react';
function SvgSquaresStacked(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4 9.6H18V18H9.6v-3.6"
        stroke={props.color}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M14.4 6H6v8.4h8.4V6z"
        stroke={props.color}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </svg>
  );
}
export default SvgSquaresStacked;

