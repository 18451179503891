"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowAuditComponent = WorkflowAuditComponent;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
var InputBlockMedia_1 = require("@shared/components/blocks/lib/inputFile/InputBlockMedia");
var hooks_1 = require("@shared/components/blocks/lib/inputFile/hooks");
var actionButton_1 = require("@shared/components/blocks/lib/inputQuestion/actionButton");
var file_1 = require("@shared/components/blocks/lib/inputQuestion/file");
var notes_1 = require("@shared/components/blocks/lib/inputQuestion/notes");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var hooks_3 = require("../../hooks");
var PrimaryAuditComponent_1 = require("./PrimaryAuditComponent");
var WorkflowComponentStyles_1 = require("./WorkflowComponentStyles");
function WorkflowAuditComponent(props) {
    var _a, _b, _c;
    var actionButtonRef = (0, react_1.useRef)();
    var _d = (0, hooks_2.useTrigger)(), answerTrigger = _d[0], onAnswerPress = _d[1];
    var _e = (0, hooks_2.useTrigger)(), notesTrigger = _e[0], onNotesPress = _e[1];
    var primaryNodeAttribute = (0, hooks_3.getPrimaryNodeAttribute)(props.component);
    var initialValue = (_a = props.initialData) === null || _a === void 0 ? void 0 : _a[primaryNodeAttribute];
    var _f = (0, react_1.useState)(initialValue), value = _f[0], setValue = _f[1];
    var _g = (0, react_1.useState)((_b = props.initialData) === null || _b === void 0 ? void 0 : _b.files), files = _g[0], setFiles = _g[1];
    var _h = (0, react_1.useState)((_c = props.initialData) === null || _c === void 0 ? void 0 : _c.notes), notes = _h[0], setNotes = _h[1];
    var isDesktop = (0, react_redux_1.useSelector)(function (state) { return state.appState.isDesktop; });
    var maxImageWidth = isDesktop ? 300 : react_native_1.Dimensions.get('window').width / 3;
    var maxImageHeight = (maxImageWidth * 2) / 3;
    var onNotesChange = (0, react_1.useCallback)(function (updatedNotes) {
        var _a;
        setNotes(updatedNotes);
        props.onDataChanged(props.component.id, (_a = {},
            _a[primaryNodeAttribute] = value,
            _a.files = files,
            _a.notes = updatedNotes,
            _a));
    }, [primaryNodeAttribute, files, value, props]);
    var onFileAdded = (0, react_1.useCallback)(function (file) {
        var _a;
        var newValues = (0, lodash_1.isNil)(files) ? [] : __spreadArray([], files, true);
        newValues.push(file);
        setFiles(newValues);
        props.onDataChanged(props.component.id, (_a = {},
            _a[primaryNodeAttribute] = value,
            _a.files = newValues,
            _a.notes = notes,
            _a));
    }, [files, props, primaryNodeAttribute, value, notes]);
    var onFileRemoved = (0, react_1.useCallback)(function (file) {
        var _a;
        var newValues = files === null || files === void 0 ? void 0 : files.filter(function (item) {
            return item.url !== file.url;
        });
        setFiles(newValues);
        props.onDataChanged(props.component.id, (_a = {},
            _a[primaryNodeAttribute] = value,
            _a.files = newValues,
            _a.notes = notes,
            _a));
    }, [files, props, primaryNodeAttribute, value, notes]);
    var _j = (0, hooks_1.useFileUpload)({
        onFileAdded: onFileAdded,
        onFileRemoved: onFileRemoved,
    }), showFilePicker = _j.showFilePicker, uploading = _j.uploading;
    if (props.isReadOnly && (0, lodash_1.isEmpty)(value) && (0, lodash_1.isEmpty)(files) && (0, lodash_1.isEmpty)(notes)) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBView, { style: WorkflowComponentStyles_1.WorkflowComponentStyles.container },
        react_1.default.createElement(PrimaryAuditComponent_1.PrimaryAuditComponent, { component: props.component, currentValue: value, onDataChanged: props.onDataChanged, filesRef: files, notesRef: notes, setValue: setValue, answerTrigger: answerTrigger, answerButtonRef: actionButtonRef, isReadOnly: props.isReadOnly }),
        !(0, lodash_1.isEmpty)(files) && (react_1.default.createElement(components_1.MBView, { paddingBottom: 1 },
            react_1.default.createElement(components_1.MBView, { style: { borderRadius: (0, components_1.grid)(1), overflow: 'hidden' } },
                react_1.default.createElement(InputBlockMedia_1.InputBlockMedia, { files: files, maxWidth: maxImageWidth, maxHeight: maxImageHeight, minWidth: maxImageWidth / 2, compactFileView: false, editable: !props.isReadOnly, onRemoved: onFileRemoved })))),
        (notes === null || notes === void 0 ? void 0 : notes.length) > 0 && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: onNotesPress },
            react_1.default.createElement(components_1.MBView, { style: WorkflowComponentStyles_1.WorkflowComponentStyles.textarea, paddingVertical: 2, paddingHorizontal: 1 },
                react_1.default.createElement(components_1.MBTextBody1, null, notes)))),
        !props.isReadOnly && (react_1.default.createElement(components_1.MBView, { row: true },
            !(0, hooks_3.isAuditCheckboxComponent)(props.component) && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(actionButton_1.MBQuestionInputActionButton, { label: (0, i18n_1.i18n)('card.blocks.question.answerButtonLabel'), onPress: onAnswerPress, icon: "pencil", innerRef: actionButtonRef }),
                react_1.default.createElement(components_1.MBView, { paddingRight: 1 }))),
            react_1.default.createElement(file_1.MBQuestionFile, { label: (0, i18n_1.i18n)('card.blocks.question.addFile'), onPress: showFilePicker, uploading: uploading }),
            react_1.default.createElement(components_1.MBView, { paddingRight: 1 }),
            react_1.default.createElement(notes_1.MBQuestionNotes, { label: (0, i18n_1.i18n)('card.blocks.question.notes'), placeholder: (0, i18n_1.i18n)('card.blocks.question.notesPlaceholder'), value: notes, onChange: onNotesChange, trigger: notesTrigger, isReadOnly: props.isReadOnly })))));
}
