"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBEmptyListPlaceholder = MBEmptyListPlaceholder;
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var button_1 = require("@shared/components/button");
var image_1 = require("@shared/components/image");
var typography_1 = require("@shared/components/typography");
var view_1 = require("@shared/components/view");
var styles_1 = require("@shared/styles");
function MBEmptyListPlaceholder(_a) {
    var title = _a.title, description = _a.description, image = _a.image, imageTintColor = _a.imageTintColor, _b = _a.imageSize, imageSize = _b === void 0 ? (0, view_1.grid)(24) : _b, buttonTitle = _a.buttonTitle, _c = _a.buttonTheme, buttonTheme = _c === void 0 ? 'outline' : _c, onButtonPress = _a.onButtonPress, testID = _a.testID;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    return (react_1.default.createElement(view_1.MBView, { fill: true, center: true, padding: 2, testID: testID },
        image && (react_1.default.createElement(image_1.MBImage, { source: image, tintColor: imageTintColor !== undefined ? imageTintColor : colors.highlightActive, style: { height: imageSize, width: '100%' }, resizeMode: "contain" })),
        image && react_1.default.createElement(view_1.MBView, { paddingTop: 2 }),
        !!title && (react_1.default.createElement(typography_1.MBTextH4, { align: "center", color: colors.foregroundActive, style: styles.title }, title)),
        react_1.default.createElement(view_1.MBView, { paddingTop: 2 }),
        !!description && (react_1.default.createElement(typography_1.MBTextBody1, { align: "center", color: colors.foregroundInactive, style: styles.description }, description)),
        react_1.default.createElement(view_1.MBView, { paddingTop: 2 }),
        !!buttonTitle && !!onButtonPress && (react_1.default.createElement(button_1.MBButton, { size: "small", theme: buttonTheme, title: buttonTitle, onPress: onButtonPress }))));
}
var styles = react_native_1.StyleSheet.create({
    title: {
        // @ts-ignore
        textWrap: 'balance',
        maxWidth: (0, view_1.grid)(60),
    },
    description: {
        // @ts-ignore
        textWrap: 'balance',
        maxWidth: (0, view_1.grid)(60),
    },
});
