"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupData = getGroupData;
exports.getGroupId = getGroupId;
exports.getGroupKey = getGroupKey;
exports.getGroupComponentId = getGroupComponentId;
function getGroupData(group) {
    return Array.from(group.values())[0];
}
function getGroupId(group) {
    var groupData = getGroupData(group);
    return groupData.selection[0].value;
}
// some group ids are numeric, but FlatList requires a string key.
function getGroupKey(group) {
    var groupId = getGroupId(group);
    return groupId.toString();
}
function getGroupComponentId(group) {
    var _a;
    var groupData = getGroupData(group);
    return (_a = groupData.filter) === null || _a === void 0 ? void 0 : _a.id;
}
