"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailField = void 0;
var lodash_1 = require("lodash");
var WorkflowEmailComponent_1 = require("@shared/scenes/workflows/views/view-components/WorkflowEmailComponent");
var i18n_1 = require("@shared/util/i18n");
var EmailField = /** @class */ (function () {
    function EmailField(jsonBlob) {
        this.fieldType = 'EmailField';
        this.jsonBlob = jsonBlob;
    }
    EmailField.prototype.validate = function () {
        if ((0, WorkflowEmailComponent_1.validateEmails)(this.jsonBlob)) {
            return null;
        }
        return (0, i18n_1.i18n)('workflowTemplate.component.error.email.invalid');
    };
    EmailField.prototype.isEqual = function (field) {
        return field instanceof EmailField && (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob);
    };
    EmailField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(EmailField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return EmailField;
}());
exports.EmailField = EmailField;
