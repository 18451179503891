"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateTag = useUpdateTag;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useUpdateTag(workflowTemplateId, componentId) {
    var _this = this;
    var _a = (0, react_1.useState)(), error = _a[0], setError = _a[1];
    var _b = (0, hooks_1.useUpdateWorkflowTemplate)(), updateWorkflowTemplate = _b[0], updateLoading = _b[1].loading;
    var updateTag = (0, react_1.useCallback)(function (id, newTagValues) { return __awaiter(_this, void 0, void 0, function () {
        var workflowTemplate, tagComponent, existingTag, newTag, newTagComponent, newWorkflowComponents, updateResult;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0: return [4 /*yield*/, (0, hooks_1.getLatestWorkflowTemplate)(workflowTemplateId)];
                case 1:
                    workflowTemplate = (_h.sent()).workflowTemplate;
                    if ((0, lodash_1.isNil)(workflowTemplate)) {
                        setError('No workflow template found.');
                        return [2 /*return*/];
                    }
                    tagComponent = workflowTemplate.components.find(function (component) { return component.id === componentId; });
                    if ((0, lodash_1.isNil)(tagComponent) || !(0, hooks_1.isTagComponent)(tagComponent)) {
                        setError('No component found.');
                        return [2 /*return*/];
                    }
                    existingTag = (_a = tagComponent.options) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.value === id; });
                    if ((0, lodash_1.isNil)(existingTag)) {
                        setError('Tag not found.');
                        return [2 /*return*/];
                    }
                    newTag = __assign(__assign({}, existingTag), newTagValues);
                    newTagComponent = __assign(__assign({}, tagComponent), { options: (_c = (_b = tagComponent.options) === null || _b === void 0 ? void 0 : _b.map(function (option) { return (option.value === id ? newTag : option); })) !== null && _c !== void 0 ? _c : [] });
                    newWorkflowComponents = (_e = (_d = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) === null || _d === void 0 ? void 0 : _d.map(function (component) {
                        return component.id !== componentId ? component : newTagComponent;
                    })) !== null && _e !== void 0 ? _e : [];
                    return [4 /*yield*/, updateWorkflowTemplate(__assign(__assign({}, workflowTemplate), { components: newWorkflowComponents }))];
                case 2:
                    updateResult = _h.sent();
                    if (!(0, lodash_1.isNil)(updateResult.errors)) {
                        setError((_g = (_f = (0, lodash_1.first)(updateResult.errors)) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : '');
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, newTag];
            }
        });
    }); }, [workflowTemplateId, componentId, updateWorkflowTemplate]);
    return {
        updateTag: updateTag,
        loading: updateLoading,
        error: error,
    };
}
