"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityField = void 0;
exports.useRelatedEntities = useRelatedEntities;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var RelatedEntityField = /** @class */ (function () {
    function RelatedEntityField(jsonBlob, entityRefs) {
        var _a;
        this.fieldType = 'RelatedEntityField';
        this.items = jsonBlob;
        var itemIds = this.items.map(function (item) { return item.id; });
        this.relatedEntities = (_a = entityRefs === null || entityRefs === void 0 ? void 0 : entityRefs.filter(function (entity) { return itemIds.includes(entity.id); })) !== null && _a !== void 0 ? _a : [];
    }
    RelatedEntityField.prototype.validate = function () {
        return null;
    };
    RelatedEntityField.prototype.isEqual = function (field) {
        return field instanceof RelatedEntityField && (0, lodash_1.isEqual)(this.items, field.items);
    };
    RelatedEntityField.prototype.toFieldJson = function () {
        return this.items;
    };
    Object.defineProperty(RelatedEntityField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isEmpty)(this.items);
        },
        enumerable: false,
        configurable: true
    });
    return RelatedEntityField;
}());
exports.RelatedEntityField = RelatedEntityField;
function useRelatedEntities(field, workflowTemplateId) {
    var missingEntities = (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isNil)(field)) {
            return [];
        }
        return field.items.filter(function (item) { return !field.relatedEntities.some(function (entity) { return entity.id === item.id; }); });
    }, [field]);
    var _a = (0, hooks_1.useWorkflowEntities)(workflowTemplateId, missingEntities.map(function (item) { return item.id; })), workflowEntities = _a.workflowEntities, loading = _a.loading;
    var entities = (0, react_1.useMemo)(function () { var _a; return __spreadArray(__spreadArray([], ((_a = field === null || field === void 0 ? void 0 : field.relatedEntities) !== null && _a !== void 0 ? _a : []), true), (workflowEntities !== null && workflowEntities !== void 0 ? workflowEntities : []), true); }, [field, workflowEntities]);
    return { loading: loading, entities: entities };
}
