"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggableContextProvider = DraggableContextProvider;
exports.getDraggableId = getDraggableId;
var client_1 = require("@apollo/client");
var core_1 = require("@dnd-kit/core");
var sortable_1 = require("@dnd-kit/sortable");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var entitySelection_1 = require("@shared/api/providers/entitySelection");
var queries_1 = require("@shared/api/queries");
var components_1 = require("@shared/components");
var useMoveWorkflowEntities_1 = require("@shared/scenes/entity/edit/hooks/useMoveWorkflowEntities");
var useUpdateWorkflowEntities_1 = require("@shared/scenes/entity/edit/hooks/useUpdateWorkflowEntities");
var notNil_1 = require("@shared/util/notNil");
var DragProxy_1 = require("./DragProxy");
var DraggableContext_1 = require("./DraggableContext");
var dndContextConfig = {
    autoScroll: {
        activator: core_1.AutoScrollActivator.DraggableRect,
        acceleration: 1,
        layoutShiftCompensation: true,
    },
    collisionDetection: core_1.pointerWithin,
    measuring: {
        droppable: {
            strategy: core_1.MeasuringStrategy.Always,
            frequency: 10,
        },
    },
};
var pointerSensorConfig = {
    activationConstraint: {
        distance: (0, components_1.grid)(0.5),
    },
};
var defaultDraggableState = {
    isDragging: false,
    activeCards: [],
};
function DraggableContextProvider(_a) {
    var _this = this;
    var children = _a.children, viewTemplate = _a.viewTemplate, workflowTemplate = _a.workflowTemplate, setEntityGroupState = _a.setEntityGroupState, isDragSortEnabled = _a.isDragSortEnabled;
    var _b = (0, react_1.useState)(defaultDraggableState), draggableState = _b[0], setDraggableState = _b[1];
    var apolloClient = (0, client_1.useApolloClient)();
    var workflowTemplateId = workflowTemplate.id;
    var viewTemplateId = viewTemplate.id;
    var updateWorkflowEntities = (0, useUpdateWorkflowEntities_1.useUpdateWorkflowEntities)({ workflowTemplateId: workflowTemplateId });
    var moveWorkflowEntity = (0, useMoveWorkflowEntities_1.useMoveWorkflowEntities)(viewTemplateId);
    var selectedEntities = (0, entitySelection_1.useEntitySelection)().selectedEntities;
    var _c = (0, react_1.useState)([]), mutations = _c[0], setMutations = _c[1];
    var mutationLoading = updateWorkflowEntities.loading || moveWorkflowEntity.loading;
    var pointerSensor = (0, core_1.useSensor)(core_1.PointerSensor, pointerSensorConfig);
    var sensors = (0, core_1.useSensors)(pointerSensor);
    var onDragStart = (0, react_1.useCallback)(function (event) {
        setEntityGroupState(function (entityGroupState) {
            var _a, _b;
            var isDragging = true;
            var activeGroupId = (_a = event.active.data.current) === null || _a === void 0 ? void 0 : _a.groupId;
            var activeEntity = (_b = event.active.data.current) === null || _b === void 0 ? void 0 : _b.entity;
            var activeEntities = getActiveEntities({ activeEntity: activeEntity, selectedEntities: selectedEntities });
            var activeCards = activeEntities
                .map(function (entity) { return toActiveCard({ entity: entity, entityGroupState: entityGroupState, apolloClient: apolloClient }); })
                .filter(notNil_1.notNil);
            setDraggableState({ isDragging: isDragging, activeCards: activeCards, activeGroupId: activeGroupId });
            return entityGroupState;
        });
    }, [apolloClient, setEntityGroupState, selectedEntities]);
    var onDragOver = (0, react_1.useCallback)(function (event) {
        var collisions = event.collisions;
        var overGroup = collisions === null || collisions === void 0 ? void 0 : collisions.find(function (collision) { var _a; return ((_a = collision.data) === null || _a === void 0 ? void 0 : _a.droppableContainer.data.current.type) === 'group'; });
        setDraggableState(function (prevDraggableState) {
            var activeCards = prevDraggableState.activeCards, activeGroupId = prevDraggableState.activeGroupId;
            if (!activeCards) {
                return prevDraggableState;
            }
            activeCards.forEach(function (activeCard) {
                setEntityGroupState(function (entityGroupState) {
                    var _a, _b;
                    var activeEntity = activeCard.workflowEntity;
                    var oldGroupId = activeCard.groupId;
                    var newGroupId = overGroup === null || overGroup === void 0 ? void 0 : overGroup.id;
                    if ((0, lodash_1.isNil)(activeEntity) ||
                        (0, lodash_1.isNil)(oldGroupId) ||
                        (0, lodash_1.isNil)(newGroupId) ||
                        (0, lodash_1.isNil)(activeGroupId)) {
                        return entityGroupState;
                    }
                    var newGroup = entityGroupState[newGroupId];
                    var groupComponentId = newGroup.groupComponentId;
                    var filterType = (_b = (_a = newGroup.groupData.filter) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : '';
                    var currentValues = getCurrentValues({
                        entity: activeEntity,
                        groupComponentId: groupComponentId,
                        filterType: filterType,
                    });
                    var updatedValues = getUpdatedValues({
                        currentValues: currentValues,
                        oldGroupId: oldGroupId,
                        newGroupId: newGroupId,
                    });
                    updateApolloCache({
                        apolloClient: apolloClient,
                        entity: activeEntity,
                        entityGroupState: entityGroupState,
                        updatedValues: updatedValues,
                    });
                    return entityGroupState;
                });
            });
            return __assign(__assign({}, prevDraggableState), { activeGroupId: overGroup === null || overGroup === void 0 ? void 0 : overGroup.id });
        });
    }, [apolloClient, setEntityGroupState]);
    var onDragEnd = (0, react_1.useCallback)(function (event) {
        var over = event.over;
        setDraggableState(function (prevDraggableState) {
            var activeCards = prevDraggableState.activeCards, activeGroupId = prevDraggableState.activeGroupId;
            if (!activeCards || (0, lodash_1.isNil)(activeGroupId)) {
                return prevDraggableState;
            }
            var entityUpdates = [];
            // loop through active cards and create a mutation to update the group
            // value for each card
            activeCards.forEach(function (activeCard) {
                setEntityGroupState(function (entityGroupState) {
                    var _a;
                    var _b, _c;
                    var activeWorkflowEntity = activeCard.workflowEntity;
                    var oldGroupId = activeCard.groupId;
                    var groupHasChanged = oldGroupId !== activeGroupId;
                    if ((0, lodash_1.isNil)(activeWorkflowEntity)) {
                        return entityGroupState;
                    }
                    if (groupHasChanged) {
                        var activeGroup = entityGroupState[activeGroupId];
                        var groupComponentId = activeGroup.groupComponentId;
                        var filterType_1 = (_c = (_b = activeGroup.groupData.filter) === null || _b === void 0 ? void 0 : _b.type) !== null && _c !== void 0 ? _c : '';
                        var currentValues = getCurrentValues({
                            entity: activeWorkflowEntity,
                            groupComponentId: groupComponentId,
                            filterType: filterType_1,
                        });
                        var updatedValues = getUpdatedValues({
                            currentValues: currentValues,
                            oldGroupId: oldGroupId,
                            newGroupId: activeGroupId,
                        });
                        var groupItemValues = updatedValues.map(function (item) {
                            return getGroupItemValue(item, filterType_1);
                        });
                        var updatedFields = (_a = {},
                            _a[groupComponentId] = groupItemValues,
                            _a);
                        entityUpdates.push({
                            id: activeWorkflowEntity.id,
                            fields: updatedFields,
                        });
                    }
                    return entityGroupState;
                });
            });
            if (entityUpdates.length > 0) {
                var updateEntites_1 = function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        updateWorkflowEntities.mutate(entityUpdates);
                        return [2 /*return*/];
                    });
                }); };
                setMutations(function (prev) { return __spreadArray(__spreadArray([], prev, true), [updateEntites_1], false); });
            }
            // reorder the cards in the new group
            setEntityGroupState(function (entityGroupState) {
                var _a;
                var _b, _c, _d;
                var overEntityId = (_b = over === null || over === void 0 ? void 0 : over.data.current) === null || _b === void 0 ? void 0 : _b.entityId;
                var activeGroup = entityGroupState[activeGroupId];
                var activeWorkflowEntities = activeCards.map(function (card) { return card.workflowEntity; });
                var activeEntities = activeCards.map(function (card) { return card.entity; });
                var groupCache = apolloClient.readQuery({
                    query: queries_1.Queries.workflow.listWorkflowEntities,
                    variables: activeGroup.entityVariables,
                });
                var cachedWorkflowEntities = groupCache.list.entities;
                var updatedWorkflowEntities = getUpdatedEntityList({
                    cachedEntities: cachedWorkflowEntities,
                    activeEntities: activeWorkflowEntities,
                    overEntityId: overEntityId,
                });
                var group = entityGroupState[activeGroupId];
                var cachedEntities = group.entities;
                var updatedEntities = getUpdatedEntityList({
                    cachedEntities: cachedEntities,
                    activeEntities: activeEntities,
                    overEntityId: overEntityId,
                });
                var firstActiveCard = activeCards[0];
                var lastActiveCard = activeCards[activeCards.length - 1];
                var newStartIndex = updatedEntities.findIndex(function (item) { return item.id === firstActiveCard.entity.id; });
                var newEndIndex = updatedEntities.findIndex(function (item) { return item.id === lastActiveCard.entity.id; });
                var newPrevEntity = updatedEntities[newStartIndex - 1];
                var newNextEntity = updatedEntities[newEndIndex + 1];
                var hasNeighbor = newPrevEntity || newNextEntity;
                var prevNeighborChanged = (newPrevEntity === null || newPrevEntity === void 0 ? void 0 : newPrevEntity.id) !== ((_c = firstActiveCard.prevEntity) === null || _c === void 0 ? void 0 : _c.id);
                var nextNeighborChanged = (newNextEntity === null || newNextEntity === void 0 ? void 0 : newNextEntity.id) !== ((_d = firstActiveCard.nextEntity) === null || _d === void 0 ? void 0 : _d.id);
                var positionHasChanged = prevNeighborChanged || nextNeighborChanged;
                if (hasNeighbor && positionHasChanged && isDragSortEnabled) {
                    var moveEntities_1 = function () { return __awaiter(_this, void 0, void 0, function () {
                        var entityIds;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    entityIds = activeCards.map(function (card) { return card.workflowEntity.id; });
                                    return [4 /*yield*/, moveWorkflowEntity.mutate({
                                            entityIds: entityIds,
                                            afterEntityIdDescending: newPrevEntity === null || newPrevEntity === void 0 ? void 0 : newPrevEntity.id,
                                            beforeEntityIdDescending: newNextEntity === null || newNextEntity === void 0 ? void 0 : newNextEntity.id,
                                        })];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    setMutations(function (prev) { return __spreadArray(__spreadArray([], prev, true), [moveEntities_1], false); });
                }
                if (isDragSortEnabled) {
                    apolloClient.writeQuery({
                        query: queries_1.Queries.workflow.listWorkflowEntities,
                        variables: activeGroup.entityVariables,
                        data: __assign(__assign({}, groupCache), { list: __assign(__assign({}, groupCache.list), { entities: updatedWorkflowEntities }) }),
                    });
                    return __assign(__assign({}, entityGroupState), (_a = {}, _a[activeGroupId] = __assign(__assign({}, activeGroup), { entities: updatedEntities }), _a));
                }
                else {
                    return entityGroupState;
                }
            });
            // reset draggable state at end of drag
            return defaultDraggableState;
        });
    }, [
        apolloClient,
        updateWorkflowEntities,
        moveWorkflowEntity,
        setEntityGroupState,
        isDragSortEnabled,
    ]);
    var onDragCancel = (0, react_1.useCallback)(function () {
        setDraggableState(defaultDraggableState);
    }, []);
    // execute mutations after the drag has ended, one at a time
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isEmpty)(mutations) || mutationLoading) {
            return;
        }
        var mutation = mutations[0], rest = mutations.slice(1);
        mutation();
        setMutations(rest);
    }, [mutations, mutationLoading]);
    var isDragging = draggableState.isDragging, activeCards = draggableState.activeCards, activeGroupId = draggableState.activeGroupId;
    var contextValue = (0, react_1.useMemo)(function () { return ({
        isDragEnabled: true,
        isDragSortEnabled: isDragSortEnabled,
        isDragging: isDragging,
        activeCards: activeCards,
        activeGroupId: activeGroupId,
    }); }, [activeCards, activeGroupId, isDragSortEnabled, isDragging]);
    return (react_1.default.createElement(core_1.DndContext, __assign({ sensors: sensors, onDragStart: onDragStart, onDragOver: onDragOver, onDragEnd: onDragEnd, onDragCancel: onDragCancel }, dndContextConfig),
        react_1.default.createElement(DraggableContext_1.DraggableContext.Provider, { value: contextValue }, children),
        react_1.default.createElement(core_1.DragOverlay, null, activeCards && react_1.default.createElement(DragProxy_1.DragProxy, { activeCards: activeCards, viewTemplate: viewTemplate, workflowTemplate: workflowTemplate }))));
}
function updateApolloCache(_a) {
    var apolloClient = _a.apolloClient, entity = _a.entity, entityGroupState = _a.entityGroupState, updatedValues = _a.updatedValues;
    Object.values(entityGroupState).forEach(function (group) {
        var _a;
        var _b, _c;
        var groupCache = apolloClient.readQuery({
            query: queries_1.Queries.workflow.listWorkflowEntities,
            variables: group.entityVariables,
        });
        if (!groupCache) {
            return;
        }
        var hasEntity = groupCache.list.entities.some(function (item) { return item.id === entity.id; });
        var shouldHaveEntity = updatedValues.includes(group.groupId) ||
            (group.groupId === 'empty' && (0, lodash_1.isEmpty)(updatedValues));
        var groupComponentId = group.groupComponentId;
        var filterType = (_c = (_b = group.groupData.filter) === null || _b === void 0 ? void 0 : _b.type) !== null && _c !== void 0 ? _c : '';
        var groupItemValues = updatedValues.map(function (item) { return getGroupItemValue(item, filterType); });
        var updates = (_a = {},
            _a[groupComponentId] = groupItemValues,
            _a);
        var updatedEntity = __assign(__assign({}, entity), { fields: __assign(__assign({}, entity.fields), updates) });
        if (shouldHaveEntity && hasEntity) {
            apolloClient.writeQuery({
                query: queries_1.Queries.workflow.listWorkflowEntities,
                variables: group.entityVariables,
                data: __assign(__assign({}, groupCache), { list: __assign(__assign({}, groupCache.list), { entities: groupCache.list.entities.map(function (item) {
                            if (item.id === entity.id) {
                                return updatedEntity;
                            }
                            else {
                                return item;
                            }
                        }) }) }),
            });
        }
        else if (shouldHaveEntity && !hasEntity) {
            // if we somehow have more items in the cache than the totalResults, use
            // the larger of the two numbers, so the rendered card count matches the
            // number of cards shown in the column
            var totalResults = Math.max(groupCache.list.totalResults + 1, groupCache.list.entities.length + 1);
            apolloClient.writeQuery({
                query: queries_1.Queries.workflow.listWorkflowEntities,
                variables: group.entityVariables,
                data: __assign(__assign({}, groupCache), { list: __assign(__assign({}, groupCache.list), { totalResults: totalResults, entities: __spreadArray([updatedEntity], groupCache.list.entities, true) }) }),
            });
        }
        else if (!shouldHaveEntity && hasEntity) {
            var totalResults = Math.max(0, groupCache.list.totalResults - 1);
            apolloClient.writeQuery({
                query: queries_1.Queries.workflow.listWorkflowEntities,
                variables: group.entityVariables,
                data: __assign(__assign({}, groupCache), { list: __assign(__assign({}, groupCache.list), { totalResults: totalResults, entities: groupCache.list.entities.filter(function (item) { return item.id !== entity.id; }) }) }),
            });
        }
    });
}
function getCurrentValues(_a) {
    var _b, _c;
    var entity = _a.entity, groupComponentId = _a.groupComponentId, filterType = _a.filterType;
    var fieldValues = (_c = (_b = entity.fields) === null || _b === void 0 ? void 0 : _b[groupComponentId]) !== null && _c !== void 0 ? _c : [];
    if (filterType === 'RELATED_CARD') {
        return fieldValues.map(function (_a) {
            var id = _a.id;
            return id;
        });
    }
    else {
        return fieldValues;
    }
}
function getUpdatedValues(_a) {
    var currentValues = _a.currentValues, oldGroupId = _a.oldGroupId, newGroupId = _a.newGroupId;
    if (newGroupId === 'empty') {
        return [];
    }
    else if (oldGroupId === 'empty') {
        return [newGroupId];
    }
    else if (oldGroupId === newGroupId) {
        return currentValues;
    }
    else {
        return __spreadArray(__spreadArray([], currentValues.filter(function (item) { return item !== oldGroupId && item !== newGroupId; }), true), [
            newGroupId,
        ], false);
    }
}
function getGroupItemValue(value, filterType) {
    if (filterType === 'RELATED_CARD') {
        return { id: value };
    }
    else {
        return value;
    }
}
function getDraggableId(_a) {
    var entityId = _a.entityId, groupId = _a.groupId, activeGroupId = _a.activeGroupId, activeCards = _a.activeCards;
    var firstActiveCard = activeCards === null || activeCards === void 0 ? void 0 : activeCards[0];
    var isActiveGroup = activeGroupId === groupId;
    var isFirstActiveCard = firstActiveCard && firstActiveCard.entity.id === entityId;
    if (activeCards && isActiveGroup && isFirstActiveCard) {
        return firstActiveCard.id;
    }
    else {
        return "".concat(groupId, "-").concat(entityId);
    }
}
function toActiveCard(_a) {
    var entity = _a.entity, entityGroupState = _a.entityGroupState, apolloClient = _a.apolloClient;
    var group = Object.values(entityGroupState).find(function (g) {
        return g.entities.some(function (item) { return item.id === entity.id; });
    });
    if (!group) {
        return undefined;
    }
    var groupCache = apolloClient.readQuery({
        query: queries_1.Queries.workflow.listWorkflowEntities,
        variables: group.entityVariables,
    });
    var index = groupCache.list.entities.findIndex(function (item) { return item.id === entity.id; });
    var workflowEntity = groupCache.list.entities[index];
    var prevEntity = groupCache.list.entities[index - 1];
    var nextEntity = groupCache.list.entities[index + 1];
    return {
        id: getDraggableId({ entityId: entity.id, groupId: group.groupId }),
        entity: entity,
        workflowEntity: workflowEntity,
        groupId: group.groupId,
        prevEntity: prevEntity,
        nextEntity: nextEntity,
    };
}
function getActiveEntities(_a) {
    var activeEntity = _a.activeEntity, selectedEntities = _a.selectedEntities;
    // If entity selection is active, and the event.active card is in the
    // selection, build the array of active cards from the selection. If
    // not, just use the event.active card.
    var isSelected = selectedEntities.some(function (entity) { return entity.id === activeEntity.id; });
    if (!isSelected) {
        return [activeEntity];
    }
    else {
        return __spreadArray([activeEntity], selectedEntities.filter(function (entity) { return entity.id !== activeEntity.id; }), true);
    }
}
function getUpdatedEntityList(_a) {
    var cachedEntities = _a.cachedEntities, activeEntities = _a.activeEntities, overEntityId = _a.overEntityId;
    var firstActiveEntity = activeEntities[0], otherActiveEntities = activeEntities.slice(1);
    var activeIndex = cachedEntities.findIndex(function (item) { return item.id === firstActiveEntity.id; });
    var overIndex = cachedEntities.findIndex(function (item) { return item.id === overEntityId; });
    var updatedEntities = (0, sortable_1.arrayMove)(cachedEntities, activeIndex, overIndex);
    return updatedEntities
        .filter(function (item) { return !otherActiveEntities.some(function (activeItem) { return activeItem.id === item.id; }); })
        .reduce(function (memo, item) {
        if (item.id === firstActiveEntity.id) {
            return __spreadArray(__spreadArray(__spreadArray([], memo, true), [firstActiveEntity], false), otherActiveEntities, true);
        }
        else {
            return __spreadArray(__spreadArray([], memo, true), [item], false);
        }
    }, []);
}
