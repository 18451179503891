"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGroupCollapse = useGroupCollapse;
var lodash_1 = require("lodash");
var react_1 = require("react");
var storage_1 = require("@shared/util/storage");
var GROUP_STATE_RECORD_CACHE_KEY = 'groupStateRecord';
function useGroupCollapse(_a) {
    var _this = this;
    var _b, _c;
    var viewTemplateId = _a.viewTemplateId, group = _a.group, _d = _a.defaultValue, defaultValue = _d === void 0 ? false : _d;
    // The isCollapsed state is boolean or undefined. If undefined, the user has
    // not set a preference, so we return the default value below. Important: The
    // defaultValue is passed directly through, not mediated in state, so if that
    // prop changes (e.g. if totalCount changes from 0 to 1), then the returned
    // value below will change. Once the user sets a preference, we store it in
    // the cache and always return that value, ignoring the defaultValue.
    var _e = (0, react_1.useState)(), isCollapsed = _e[0], setIsCollapsed = _e[1];
    var selectionState = Array.from(group.values())[0];
    var componentId = (_c = (_b = selectionState === null || selectionState === void 0 ? void 0 : selectionState.filter) === null || _b === void 0 ? void 0 : _b.component) === null || _c === void 0 ? void 0 : _c.id;
    var groupId = selectionState === null || selectionState === void 0 ? void 0 : selectionState.selection[0].value;
    var storageKey = getStorageKey({ viewTemplateId: viewTemplateId, componentId: componentId, groupId: groupId });
    (0, react_1.useEffect)(function () {
        // check the stored state on mount
        var getCollapsedStateFromCache = function () { return __awaiter(_this, void 0, void 0, function () {
            var cachedState, cachedValue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!(0, lodash_1.isNil)(storageKey)) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCachedState()];
                    case 1:
                        cachedState = _a.sent();
                        cachedValue = cachedState[storageKey];
                        if (!(0, lodash_1.isNil)(cachedValue)) {
                            setIsCollapsed(cachedValue);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        getCollapsedStateFromCache();
    }, [storageKey]);
    (0, react_1.useEffect)(function () {
        // store the collapsed state if it's ever set to a boolean
        var storeCollapsedState = function () { return __awaiter(_this, void 0, void 0, function () {
            var cachedState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!(0, lodash_1.isNil)(storageKey) && !(0, lodash_1.isNil)(isCollapsed))) return [3 /*break*/, 3];
                        return [4 /*yield*/, getCachedState()];
                    case 1:
                        cachedState = _a.sent();
                        cachedState[storageKey] = isCollapsed;
                        return [4 /*yield*/, storage_1.MBStorage.setJSON(GROUP_STATE_RECORD_CACHE_KEY, cachedState)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        storeCollapsedState();
    }, [isCollapsed, storageKey]);
    var returnValue = (0, lodash_1.isNil)(isCollapsed) ? defaultValue : isCollapsed;
    return [returnValue, setIsCollapsed];
}
function getStorageKey(_a) {
    var viewTemplateId = _a.viewTemplateId, componentId = _a.componentId, groupId = _a.groupId;
    if ((0, lodash_1.isNil)(componentId) || (0, lodash_1.isNil)(groupId)) {
        return undefined;
    }
    return "".concat(viewTemplateId, "-").concat(componentId, "-").concat(groupId);
}
function getCachedState() {
    return __awaiter(this, void 0, void 0, function () {
        var cachedState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, storage_1.MBStorage.getJSON(GROUP_STATE_RECORD_CACHE_KEY)];
                case 1:
                    cachedState = _a.sent();
                    return [2 /*return*/, cachedState !== null && cachedState !== void 0 ? cachedState : {}];
            }
        });
    });
}
