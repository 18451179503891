"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileEditField = FileEditField;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var FileInput_1 = require("@shared/components/blocks/lib/inputFile/FileInput");
var FileField_1 = require("@shared/data/entity/fields/FileField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var EditFieldContainer_1 = require("./EditFieldContainer");
function FileEditField(_a) {
    var _b, _c;
    var field = _a.field, component = _a.component, updateField = _a.updateField, error = _a.error;
    var files = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.files) !== null && _a !== void 0 ? _a : []; }, [field === null || field === void 0 ? void 0 : field.files]);
    var isReadOnly = (_b = component.readonly) !== null && _b !== void 0 ? _b : false;
    var onFileAdded = (0, react_1.useCallback)(function (file) {
        var newFiles = __spreadArray(__spreadArray([], files, true), [file], false);
        updateField(component.id, new FileField_1.FileField(newFiles));
    }, [component.id, files, updateField]);
    var onFileRemoved = (0, react_1.useCallback)(function (file) {
        var newFiles = files.filter(function (value) {
            return value.url !== file.url;
        });
        updateField(component.id, new FileField_1.FileField(newFiles));
    }, [component.id, files, updateField]);
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: true, indentLabel: true, showIcon: false, error: error },
        react_1.default.createElement(components_1.MBView, { style: {
                marginHorizontal: -(0, components_1.grid)(1),
                marginVertical: (0, components_1.grid)(-0.5),
            } },
            react_1.default.createElement(FileInput_1.FileInput, { files: files, icon: (0, hooks_1.getComponentIcon)(component), placeholder: (0, hooks_1.getComponentPlaceholder)(component), acceptedContentTypes: (_c = component.acceptedContentTypes) !== null && _c !== void 0 ? _c : [], onFileAdded: onFileAdded, onFileRemoved: onFileRemoved, isReadOnly: isReadOnly }))));
}
