"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTestId = getTestId;
var notNil_1 = require("./notNil");
function getTestId() {
    var parts = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        parts[_i] = arguments[_i];
    }
    return parts.filter(notNil_1.notNil).join('-').replace(/[& ]/g, '-');
}
