"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditCardEntity = useEditCardEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var useFormErrors_1 = require("@shared/scenes/entity/create/hooks/useFormErrors");
var useEntityFormViewTemplate_1 = require("@shared/scenes/entity/util/useEntityFormViewTemplate");
var useViewComponents_1 = require("@shared/scenes/entity/util/useViewComponents");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useEditFields_1 = require("./useEditFields");
var useSaveCardEntity_1 = require("./useSaveCardEntity");
function useEditCardEntity(_a) {
    var _b;
    var entityId = _a.entityId, workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId, _c = _a.isReadOnly, isReadOnly = _c === void 0 ? false : _c;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, useEntityFormViewTemplate_1.useEntityFormViewTemplate)({
        workflowTemplateId: workflowTemplateId,
        viewTemplateId: viewTemplateId,
        type: 'EDIT',
    });
    var _d = (0, react_1.useState)('loading'), status = _d[0], setStatus = _d[1];
    var _e = (0, react_1.useState)(false), saveAttemptedWithLocalErrors = _e[0], setSaveAttemptedWithLocalErrors = _e[1];
    var _f = (0, useEditFields_1.useEditFields)(entityId, workflowTemplate), fields = _f.fields, updateField = _f.updateField, isDirty = _f.isDirty, threadId = _f.threadId, fieldsLoading = _f.loading, fieldsErrors = _f.errors;
    var _g = (0, useSaveCardEntity_1.useSaveEntityFields)(entityId, workflowTemplate), saveEntity = _g.saveEntity, saveEntityLoading = _g.loading, apiErrors = _g.errors;
    var components = (0, useViewComponents_1.useViewComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        includeServerManagedComponents: true,
        fields: fields,
        isReadOnly: isReadOnly,
    });
    var _h = (0, useFormErrors_1.useFormErrors)({
        apiErrors: __spreadArray(__spreadArray([], apiErrors, true), fieldsErrors, true),
        fields: fields,
        components: components,
        includeRequiredErrors: saveAttemptedWithLocalErrors,
    }), errors = _h.errors, isValid = _h.isValid;
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (fieldsLoading || saveEntityLoading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [errors, fieldsLoading, saveEntityLoading, status]);
    (0, react_1.useEffect)(function () {
        if (isValid) {
            setSaveAttemptedWithLocalErrors(false);
        }
    }, [isValid]);
    var onPressSave = (0, react_1.useCallback)(function () {
        if (!isValid) {
            setSaveAttemptedWithLocalErrors(true);
            return;
        }
        setSaveAttemptedWithLocalErrors(false);
        if (!saveEntityLoading && !(0, lodash_1.isNil)(fields)) {
            saveEntity(fields).then(function (_fulfilled) {
                setStatus('success');
            }, function (_error) {
                // parse remote validation
                setStatus('error');
            });
        }
    }, [saveEntityLoading, fields, saveEntity, isValid]);
    return {
        components: components,
        fields: fields,
        errors: errors,
        viewTemplateTitle: (_b = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name) !== null && _b !== void 0 ? _b : '',
        updateField: updateField,
        onPressSave: onPressSave,
        isLoading: status === 'loading',
        status: status,
        isDirty: isDirty,
        threadId: threadId,
    };
}
