"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateCardEntity = useCreateCardEntity;
var lodash_1 = require("lodash");
var react_1 = require("react");
var BatchContext_1 = require("@shared/scenes/cards/entityBulkActions/BatchCreateContext/BatchContext");
var useFormErrors_1 = require("@shared/scenes/entity/create/hooks/useFormErrors");
var useEntityFormViewTemplate_1 = require("@shared/scenes/entity/util/useEntityFormViewTemplate");
var useViewComponents_1 = require("@shared/scenes/entity/util/useViewComponents");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useCreateEntityFields_1 = require("./useCreateEntityFields");
var useSaveNewCardEntity_1 = require("./useSaveNewCardEntity");
function useCreateCardEntity(_a) {
    var _b;
    var workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId, queryFieldOverrides = _a.queryFieldOverrides;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId).workflowTemplate;
    var viewTemplate = (0, useEntityFormViewTemplate_1.useEntityFormViewTemplate)({
        workflowTemplateId: workflowTemplateId,
        viewTemplateId: viewTemplateId,
        type: 'CREATE',
    });
    var _c = (0, react_1.useState)('loading'), status = _c[0], setStatus = _c[1];
    var _d = (0, react_1.useState)(false), saveAttemptedWithLocalErrors = _d[0], setSaveAttemptedWithLocalErrors = _d[1];
    var _e = (0, react_1.useState)(null), batchCreateError = _e[0], setBatchCreateError = _e[1];
    var _f = (0, BatchContext_1.useBatch)(), shouldBatchCreate = _f.shouldBatchCreate, batchCreate = _f.batchCreate;
    var _g = (0, useCreateEntityFields_1.useCreateEntityFields)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        queryFieldOverrides: queryFieldOverrides,
    }), fields = _g.fields, updateField = _g.updateField, isDirty = _g.isDirty, resetFields = _g.resetFields;
    var _h = (0, useSaveNewCardEntity_1.useSaveNewEntity)(workflowTemplateId), saveEntity = _h.saveEntity, saveEntityLoading = _h.loading, apiErrors = _h.errors;
    var components = (0, useViewComponents_1.useViewComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        includeServerManagedComponents: false,
        includeInputComponentsOnly: true,
    });
    var _j = (0, useFormErrors_1.useFormErrors)({
        apiErrors: batchCreateError ? [batchCreateError] : apiErrors,
        fields: fields,
        components: components,
        includeRequiredErrors: saveAttemptedWithLocalErrors,
    }), errors = _j.errors, isValid = _j.isValid;
    (0, react_1.useEffect)(function () {
        setStatus(function (prevStatus) {
            if (saveEntityLoading) {
                return 'loading';
            }
            else if (!(0, lodash_1.isEmpty)(errors)) {
                return 'error';
            }
            else if (prevStatus !== 'success') {
                return 'input';
            }
            else {
                return prevStatus;
            }
        });
    }, [errors, saveEntityLoading, status]);
    (0, react_1.useEffect)(function () {
        if (isValid) {
            setSaveAttemptedWithLocalErrors(false);
        }
    }, [isValid]);
    var save = (0, react_1.useCallback)(function () {
        var _a, _b;
        if (!isValid) {
            setSaveAttemptedWithLocalErrors(true);
            return;
        }
        setSaveAttemptedWithLocalErrors(false);
        if (shouldBatchCreate) {
            (_a = batchCreate({
                workflowTemplateId: workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : '',
                fields: (0, useSaveNewCardEntity_1.getJsonFields)(fields),
            })) === null || _a === void 0 ? void 0 : _a.then(function () {
                setStatus('success');
            }, function (error) {
                setBatchCreateError(error);
                setStatus('error');
            });
        }
        else {
            (_b = saveEntity(fields)) === null || _b === void 0 ? void 0 : _b.then(function () {
                setStatus('success');
            }, function () {
                setStatus('error');
            });
        }
    }, [fields, saveEntity, isValid, shouldBatchCreate, batchCreate, workflowTemplateId]);
    var resetForm = (0, react_1.useCallback)(function () {
        resetFields();
        setStatus('input');
    }, [resetFields, setStatus]);
    return {
        components: components,
        fields: fields,
        errors: errors,
        viewTemplateTitle: (_b = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name) !== null && _b !== void 0 ? _b : '',
        updateField: updateField,
        onPressSave: save,
        isLoading: status === 'loading',
        status: status,
        isDirty: isDirty,
        setStatus: setStatus,
        resetForm: resetForm,
    };
}
