"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkflowSupportsQuickCreate = useWorkflowSupportsQuickCreate;
var lodash_1 = require("lodash");
var react_1 = require("react");
var useDefaultFields_1 = require("@shared/scenes/entity/create/hooks/useDefaultFields");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var ComponentOptionDefaults_1 = require("@shared/scenes/workflows/template/ComponentOptionDefaults");
function useWorkflowSupportsQuickCreate(_a) {
    var workflowTemplateId = _a.workflowTemplateId;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : undefined).workflowTemplate;
    var components = (workflowTemplate !== null && workflowTemplate !== void 0 ? workflowTemplate : {}).components;
    var defaultFields = (0, useDefaultFields_1.useDefaultFields)({ workflowTemplateId: workflowTemplateId });
    return (0, react_1.useMemo)(function () {
        var _a;
        /* We hack quick create to only set the name of the related entity. This has a few downsides
         *  - Name isn't guaranteed, so we must verify it exists.
         *  - There may be other required fields that we cannot set.
         *
         *  If there isn't a name field or there are other required fields, we will not allow creation
         */
        var relatedWorkflowHasName = (_a = components === null || components === void 0 ? void 0 : components.some(function (c) { return c.id === ComponentOptionDefaults_1.STATIC_COMPONENT_IDS.NAME; })) !== null && _a !== void 0 ? _a : false;
        var relatedWorkflowHasOtherRequiredFields = components === null || components === void 0 ? void 0 : components.filter(hooks_1.isInputComponent).filter(function (c) { return c.id !== ComponentOptionDefaults_1.STATIC_COMPONENT_IDS.NAME; }).some(function (c) {
            var hasDefault = !(0, lodash_1.isEmpty)(defaultFields[c.id]);
            var isRequired = c.required;
            return isRequired && !hasDefault;
        });
        return relatedWorkflowHasName && !relatedWorkflowHasOtherRequiredFields;
    }, [components, defaultFields]);
}
