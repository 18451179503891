"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimaryAuditComponent = PrimaryAuditComponent;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var hooks_1 = require("../../hooks");
var WorkflowAuditCheckbox_1 = require("./WorkflowAuditCheckbox");
var WorkflowAuditTag_1 = require("./WorkflowAuditTag");
var WorkflowAuditTextarea_1 = require("./WorkflowAuditTextarea");
function PrimaryAuditComponent(props) {
    var onChangeHandler = getOnChange(props.component, props.setValue, props.onDataChanged, props.filesRef, props.notesRef);
    var onChange = (0, react_1.useCallback)(onChangeHandler, [onChangeHandler]);
    if ((0, hooks_1.isAuditTagComponent)(props.component)) {
        return (react_1.default.createElement(WorkflowAuditTag_1.WorkflowAuditTag, { component: props.component, tags: props.currentValue, trigger: props.answerTrigger, onChange: onChange, isReadOnly: props.isReadOnly }));
    }
    if ((0, hooks_1.isAuditTextComponent)(props.component)) {
        return (react_1.default.createElement(WorkflowAuditTextarea_1.WorkflowAuditTextarea, { answerTrigger: props.answerTrigger, label: props.component.label, value: props.currentValue, onChange: onChange, bottomSheetRef: props.answerButtonRef, isReadOnly: props.isReadOnly }));
    }
    if ((0, hooks_1.isAuditCheckboxComponent)(props.component)) {
        return (react_1.default.createElement(WorkflowAuditCheckbox_1.WorkflowAuditCheckbox, { component: props.component, checked: props.currentValue, onChange: onChange, isReadOnly: props.isReadOnly }));
    }
    return react_1.default.createElement(react_1.default.Fragment, null);
}
function getOnChange(component, setValue, onDataChanged, filesRef, notesRef) {
    if ((0, hooks_1.isAuditTagComponent)(component)) {
        return function (selectedTags) {
            setValue(selectedTags);
            onDataChanged(component.id, {
                tags: selectedTags,
                files: filesRef,
                notes: notesRef,
            });
        };
    }
    if ((0, hooks_1.isAuditTextComponent)(component)) {
        return function (text) {
            setValue(text);
            onDataChanged(component.id, { text: text, files: filesRef, notes: notesRef });
        };
    }
    if ((0, hooks_1.isAuditCheckboxComponent)(component)) {
        return function (checked) {
            setValue(checked);
            onDataChanged(component.id, { checked: checked, files: filesRef, notes: notesRef });
        };
    }
}
