"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardCreateRoute = void 0;
exports.LowCodeCreateRoute = LowCodeCreateRoute;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var toast_1 = require("@shared/components/toast");
var BatchContext_1 = require("@shared/scenes/cards/entityBulkActions/BatchCreateContext/BatchContext");
var hooks_1 = require("@shared/scenes/cards/hooks");
var CardEntityCreateForm_1 = require("@shared/scenes/entity/create/components/CardEntityCreateForm");
var useCreateCardEntity_1 = require("@shared/scenes/entity/create/hooks/useCreateCardEntity");
var dotsMenu_1 = require("@shared/scenes/workflows/edit/components/dotsMenu");
var constants_1 = require("@shared/styles/constants");
var launchDarkly_1 = require("@shared/util/featureFlags/launchDarkly");
var hooks_2 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var hooks_3 = require("@shared/util/navigation/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var container_1 = require("./container");
var hooks_4 = require("./hooks");
var CardCreateRoute = function (props) {
    var isNoCodeCreate = (0, launchDarkly_1.useFeatureFlag)(launchDarkly_1.NO_CODE_CREATE_FORM);
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: props.params.channelSlug });
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    if (isNoCodeCreate) {
        return (react_1.default.createElement(BatchContext_1.BatchCreateContextProvider, { workflowTemplateId: workflowTemplateId },
            react_1.default.createElement(NoCodeCreateRoute, __assign({}, props))));
    }
    return react_1.default.createElement(LowCodeCreateRoute, __assign({}, props));
};
exports.CardCreateRoute = CardCreateRoute;
function LowCodeCreateRoute(_a) {
    var _b, _c;
    var params = _a.params, location = _a.location;
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: params.channelSlug });
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, params.cardDefinitionId).cardDefinition;
    var title = (0, hooks_4.useFormTitle)(params.channelSlug, params.templateSlug, cardDefinition);
    var _d = (0, hooks_2.useFunctionState)(), onSubmit = _d[0], setOnSubmit = _d[1];
    var submitButtonTitle = (0, hooks_4.useFormSubmitButtonTitle)(params.channelSlug, params.templateSlug, params.cardDefinitionId);
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var fieldOverrides = (0, react_1.useMemo)(function () {
        if (!location.query.fields) {
            return undefined;
        }
        try {
            return JSON.parse(location.query.fields);
        }
        catch (error) {
            return undefined;
        }
    }, [location.query.fields]);
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: title, onBack: goBack, paddingOnWeb: "none", desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true },
            react_1.default.createElement(dotsMenu_1.MBWorkflowDotsMenu, { channelSlug: params.channelSlug, testID: "workflow-dots-menu-button" }),
            react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit, testID: "create-card-submit-button" })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: title, rightButton: react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onSubmit }), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(container_1.MBCardCreateForm, { channelSlug: params.channelSlug, templateSlug: params.templateSlug, cardDefinitionId: (_b = cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id) !== null && _b !== void 0 ? _b : undefined, workflowTemplateId: (_c = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId) !== null && _c !== void 0 ? _c : undefined, fieldOverrides: fieldOverrides, onSubmitFormButtonCallback: setOnSubmit, restoreSavedData: !!(location === null || location === void 0 ? void 0 : location.query.restoreSavedData) }))));
}
function NoCodeCreateRoute(_a) {
    var params = _a.params, location = _a.location;
    var channelSlug = params.channelSlug, templateSlug = params.templateSlug;
    var channel = (0, hooks_2.useGetChannelInfo)({ channelSlug: channelSlug });
    var workflowTemplateId = channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId;
    var goBack = (0, useNavigator_1.useNavigator)().goBack;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var onBack = (0, react_1.useCallback)(function () {
        goBack();
    }, [goBack]);
    var queryFieldOverrides = (0, react_1.useMemo)(function () {
        if (!location.query.fields) {
            return undefined;
        }
        try {
            return JSON.parse(location.query.fields);
        }
        catch (error) {
            return undefined;
        }
    }, [location.query.fields]);
    var _b = (0, useCreateCardEntity_1.useCreateCardEntity)({
        workflowTemplateId: workflowTemplateId,
        viewTemplateId: templateSlug,
        queryFieldOverrides: queryFieldOverrides,
    }), components = _b.components, fields = _b.fields, updateField = _b.updateField, errors = _b.errors, isDirty = _b.isDirty, onPressSave = _b.onPressSave, status = _b.status, viewTemplateTitle = _b.viewTemplateTitle;
    var isSaveSuccessful = status === 'success';
    var showUnsavedChangesWarning = !isSaveSuccessful && isDirty;
    (0, hooks_3.useUnsavedChangesNavigationWarning)(showUnsavedChangesWarning);
    (0, react_1.useEffect)(function () {
        if (isSaveSuccessful) {
            goBack();
            toast.show({
                icon: 'check-filled',
                message: (0, i18n_1.i18n)('card.create.success'),
            });
        }
    }, [isSaveSuccessful, goBack, toast]);
    var submitButtonTitle = (0, i18n_1.i18n)('card.update.submit');
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: viewTemplateTitle, paddingOnWeb: "none", onBack: onBack, desktopModalRightButton: react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
            react_1.default.createElement(dotsMenu_1.MBWorkflowDotsMenu, { channelSlug: params.channelSlug, testID: "workflow-dots-menu-button" }),
            react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onPressSave })), headerOnMobile: react_1.default.createElement(components_1.MBWebNavigationHeader, { title: viewTemplateTitle, rightButton: react_1.default.createElement(components_1.MBHeaderButton, { title: submitButtonTitle, onPress: onPressSave }), onPressLeft: goBack }) },
        react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
            react_1.default.createElement(CardEntityCreateForm_1.CardEntityCreateForm, { components: components, fields: fields, updateField: updateField, errors: errors, workflowTemplateId: workflowTemplateId }))));
}
