"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformsInputBlock = SubformsInputBlock;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var alert_1 = require("@shared/util/alert");
var i18n_1 = require("@shared/util/i18n");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var provider_1 = require("../../../../util/navigation/stack/provider");
var channel_1 = require("../../contexts/channel");
var form_1 = require("../../contexts/form");
var label_1 = require("../../utils/label");
var SubformsContent_1 = require("./SubformsContent");
var SubformsSheet_1 = require("./sheet/SubformsSheet");
function SubformsInputBlock(_a) {
    var block = _a.block, value = _a.value;
    var setFieldValue = (0, react_1.useContext)(form_1.CardFormContext).setFieldValue;
    var channel = (0, react_1.useContext)(channel_1.CardChannelContext);
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), sheetOpen = _b[0], setSheetOpen = _b[1];
    var containerRef = (0, react_1.useRef)();
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).workflowTemplate;
    var hasSubform = !(0, lodash_1.isNil)(value);
    var nav = (0, react_1.useContext)(provider_1.NavStackContext);
    var navigator = (0, useNavigator_1.useNavigator)();
    var channelSlug = channel === null || channel === void 0 ? void 0 : channel.slug;
    var onPressContainer = (0, react_1.useCallback)(function () {
        setSheetOpen(true);
    }, []);
    var onSelectSubform = (0, react_1.useCallback)(function (subformWorkflowTemplate) {
        var _a, _b;
        var componentsWithDefaults = (_b = (_a = subformWorkflowTemplate.components) === null || _a === void 0 ? void 0 : _a.filter(hooks_1.isAuditTagComponent).filter(function (c) { var _a; return !(0, lodash_1.isEmpty)((_a = c.default) === null || _a === void 0 ? void 0 : _a.value); })) !== null && _b !== void 0 ? _b : [];
        var defaultData = componentsWithDefaults.reduce(function (acc, component) {
            acc[component.id] = { tags: component.default.value };
            return acc;
        }, {});
        setFieldValue(block.name, {
            workflowTemplateId: subformWorkflowTemplate.id,
            data: defaultData,
        });
        setSheetOpen(false);
    }, [block.name, setFieldValue]);
    var onSelect = (0, react_1.useCallback)(function (workflowTemplateId) {
        nav.push({
            name: 'subform',
            params: {
                workflowTemplateId: workflowTemplateId,
                parentComponentId: block.name,
            },
        });
        setSheetOpen(false);
    }, [block.name, nav]);
    var onClear = (0, react_1.useCallback)(function () {
        alert_1.MBAlert.confirm({
            message: (0, i18n_1.i18n)('card.blocks.subformsInput.confirmClear'),
            confirmText: (0, i18n_1.i18n)('common.ok'),
            onConfirm: function () {
                setFieldValue(block.name, null);
            },
        });
    }, [block.name, setFieldValue]);
    var navigateToAdd = (0, react_1.useCallback)(function () {
        navigator.navigate('SubformsList', { channelSlug: channelSlug });
    }, [channelSlug, navigator]);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: !hasSubform && onPressContainer },
        block.label && (react_1.default.createElement(components_1.MBView, { paddingLeft: 3, paddingVertical: 1 },
            react_1.default.createElement(label_1.MBBlockLabel, { label: block.label }))),
        hasSubform ? (react_1.default.createElement(react_native_1.View, { ref: containerRef, style: { paddingHorizontal: (0, components_1.grid)(1), alignContent: 'center', alignItems: 'center' } },
            react_1.default.createElement(SubformsContent_1.SubformContent, { subform: value, onSelect: onSelect, onClear: onClear }))) : (react_1.default.createElement(components_1.MBView, { paddingHorizontal: 1 },
            react_1.default.createElement(react_native_1.View, { ref: containerRef },
                react_1.default.createElement(components_1.MBView, { paddingVertical: 2, center: true, style: [
                        styles.container,
                        {
                            backgroundColor: colors.backgroundInactive,
                            borderColor: colors.input.borderInactive,
                        },
                    ] },
                    react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true },
                        react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, size: (0, components_1.grid)(5), name: block.icon || 'list' }),
                            react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                            react_1.default.createElement(components_1.MBTextSubtitle, { color: colors.foregroundActive }, block.placeholder))))))),
        sheetOpen && !(0, lodash_1.isNil)(channelSlug) && (react_1.default.createElement(SubformsSheet_1.SubformsSheet, { containerRef: containerRef, blockLabel: block.label, workflowTemplate: workflowTemplate, onClose: function () { return setSheetOpen(false); }, onSelect: onSelectSubform, navigateToAdd: navigateToAdd }))));
}
var styles = react_native_1.StyleSheet.create({
    container: {
        borderRadius: (0, components_1.grid)(1),
        borderStyle: 'dashed',
        borderWidth: 2,
    },
});
