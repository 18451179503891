"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailEditField = EmailEditField;
exports.validateInput = validateInput;
var EmailValidator = __importStar(require("email-validator"));
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var EmailField_1 = require("@shared/data/entity/fields/EmailField");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var hooks_2 = require("@shared/util/hooks");
var EditFieldContainer_1 = require("./EditFieldContainer");
function EmailEditField(_a) {
    var _b;
    var field = _a.field, component = _a.component, updateField = _a.updateField, error = _a.error;
    var _c = (0, react_1.useState)((_b = field === null || field === void 0 ? void 0 : field.jsonBlob.join(', ')) !== null && _b !== void 0 ? _b : ''), value = _c[0], setValue = _c[1];
    var _d = (0, react_1.useState)(false), initialValueSet = _d[0], setInitialValueSet = _d[1];
    var _e = (0, hooks_2.useBoolean)(false), isFocused = _e[0], onFocus = _e[1], onBlur = _e[2];
    var _f = (0, ComponentFocus_1.useComponentFocus)(), setComponentFocused = _f.setComponentFocused, setComponentBlurred = _f.setComponentBlurred;
    var handleFocus = (0, react_1.useCallback)(function () {
        onFocus();
        setComponentFocused();
    }, [onFocus, setComponentFocused]);
    var handleBlur = (0, react_1.useCallback)(function () {
        onBlur();
        setComponentBlurred();
    }, [onBlur, setComponentBlurred]);
    (0, react_1.useEffect)(function () {
        var emails = getEmailsFromInput(value);
        updateField(component.id, !(0, lodash_1.isEmpty)(emails) ? new EmailField_1.EmailField(emails) : null);
    }, [value, updateField, component.id]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (initialValueSet) {
            return;
        }
        if (!(0, lodash_1.isEmpty)(field === null || field === void 0 ? void 0 : field.jsonBlob) && value !== (field === null || field === void 0 ? void 0 : field.jsonBlob.join(', '))) {
            setInitialValueSet(true);
            setValue((_a = field === null || field === void 0 ? void 0 : field.jsonBlob.join(', ')) !== null && _a !== void 0 ? _a : '');
        }
    }, [field, value, initialValueSet]);
    var onClear = (0, react_1.useCallback)(function () {
        setValue('');
    }, [setValue]);
    var hasValue = !(0, lodash_1.isEmpty)(value);
    var isReadOnly = !!component.readonly;
    var showClearButton = hasValue && !isReadOnly;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasValue, onClear: showClearButton ? onClear : undefined, error: !isFocused ? error : undefined },
        react_1.default.createElement(components_1.MBTextInput, { noBorder: true, noPadding: true, inputMode: "email", textContentType: "emailAddress", value: value, onChangeText: setValue, placeholder: (0, hooks_1.getComponentPlaceholder)(component), onBlur: handleBlur, onFocus: handleFocus, editable: !isReadOnly })));
}
function validateInput(input) {
    if ((0, lodash_1.isEmpty)(input.trim())) {
        return true;
    }
    var emails = getEmailsFromInput(input);
    return emails.every(function (email) { return EmailValidator.validate(email); });
}
function getEmailsFromInput(input) {
    return input
        .replace(/( )?,( )?/g, ',')
        .trim()
        .split(',')
        .filter(Boolean);
}
