"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSortedCardList = useSortedCardList;
var lodash_1 = require("lodash");
var react_1 = require("react");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
/*
lodash puts null values to top, we want them to bottom.
Custom iterator (iteratee?) sorts them as 0 when null/undefined.
Forces item to top when asc, bottom when desc.
*/
var FALLBACK_VALUE = 0;
function useSortedCardList() {
    var sorts = (0, ControlsProvider_1.useControlsState)().sorts;
    return (0, react_1.useCallback)(function (cards) {
        var _a;
        var allSorts = Array.from((_a = sorts === null || sorts === void 0 ? void 0 : sorts.values()) !== null && _a !== void 0 ? _a : []);
        if ((0, lodash_1.isEmpty)(allSorts)) {
            return cards;
        }
        var sortIteratees = function (item) {
            return allSorts.map(function (val) { return (0, lodash_1.get)(item, "fields.".concat(val.sort.id), FALLBACK_VALUE); });
        };
        var sortOrders = allSorts.map(function (val) { return val.direction.toLowerCase(); });
        return (0, lodash_1.orderBy)(cards, sortIteratees, sortOrders);
    }, [sorts]);
}
