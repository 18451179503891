"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBWorkspaceSection = MBWorkspaceSection;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var styles_1 = require("@shared/styles");
var getTestId_1 = require("@shared/util/getTestId");
var hooks_1 = require("@shared/util/hooks");
var ChannelListLayoutContext_1 = require("../../webLayout/ChannelListLayoutContext");
var hooks_2 = require("../hooks");
var renameSectionForm_1 = require("./renameSectionForm");
function MBWorkspaceSection(_a) {
    var section = _a.section;
    var _b = (0, hooks_1.useBoolean)(false), renameSheetVisible = _b[0], showRenameSheet = _b[1], hideRenameSheet = _b[2];
    var containerRef = react_1.default.useRef();
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var showOptions = (0, hooks_2.useShowChannelSectionOptions)({
        section: section,
        onPressRenameSection: showRenameSheet,
    });
    var state = (0, react_1.useContext)(ChannelListLayoutContext_1.ChannelListLayoutContext).state;
    if (!section.data.length) {
        return null;
    }
    if (state === 'collapsed') {
        return (react_1.default.createElement("div", { style: { paddingLeft: (0, components_1.grid)(1), paddingRight: (0, components_1.grid)(1), paddingTop: (0, components_1.grid)(1) }, title: section.title },
            react_1.default.createElement(components_1.MBSeparator, null),
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: section.toggle, style: {
                    height: (0, components_1.grid)(2),
                    alignItems: 'center',
                    paddingTop: (0, components_1.grid)(0.5),
                    marginBottom: (0, components_1.grid)(1),
                } },
                react_1.default.createElement(components_1.MBIconV2, { name: section.collapsed ? 'arrow-point-right-heavy' : 'arrow-point-down-heavy', color: styles_1.Colors.v2.greenSecondary60, size: (0, components_1.grid)(2) }))));
    }
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: section.toggle },
        react_1.default.createElement(components_1.MBView, { centerVertically: true, row: true, paddingLeft: 3, paddingBottom: 1 },
            react_1.default.createElement(components_1.MBView, { paddingTop: 1 },
                react_1.default.createElement(components_1.MBIconV2, { name: section.collapsed ? 'arrow-point-right-heavy' : 'arrow-point-down-heavy', color: colors.foregroundActive, size: (0, components_1.grid)(2) })),
            react_1.default.createElement(components_1.MBView, { flex: true, paddingHorizontal: 1, paddingTop: 1 },
                react_1.default.createElement(components_1.MBTextOverlineBold, { color: colors.foregroundActive, numberOfLines: 5 }, section.title)),
            section.unreadCount > 0 && (react_1.default.createElement(components_1.MBView, { paddingTop: 1 },
                react_1.default.createElement(components_1.MBNotificationBadge, { count: section.unreadCount, testID: (0, getTestId_1.getTestId)('workspace-section-title', section.title, 'unread-count') }))),
            react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: showOptions, ref: containerRef, testID: (0, getTestId_1.getTestId)('sidebar-new-workspace-button', section.title.toLowerCase()) },
                react_1.default.createElement(components_1.MBView, { paddingTop: 1, paddingHorizontal: 2 },
                    react_1.default.createElement(components_1.MBIconV2, { name: "dots", color: colors.foregroundActive, size: (0, components_1.grid)(3) }))),
            renameSheetVisible && (react_1.default.createElement(components_1.MBBottomSheet, { initialSize: "half", containerRef: containerRef, onClose: hideRenameSheet, layout: {
                    offset: { x: 0, y: 0 },
                    minWidth: 340,
                } },
                react_1.default.createElement(renameSectionForm_1.RenameSectionForm, { section: section, hideRenameSheet: hideRenameSheet }))))));
}
