"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardListView = CardListView;
var sortable_1 = require("@dnd-kit/sortable");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var GroupProvider_1 = require("@shared/components/filter/state/GroupProvider");
var getGroupData_1 = require("@shared/components/filter/state/getGroupData");
var GroupContainer_1 = require("@shared/scenes/cards/collectionView/components/GroupContainer");
var i18n_1 = require("@shared/util/i18n");
var emptyState_1 = require("../../components/emptyState");
var CardListViewHooks_1 = require("../hooks/CardListViewHooks");
var CardList_1 = require("./CardList");
var DraggableContext_1 = require("./DraggableContext");
var DraggableContextProvider_1 = require("./DraggableContextProvider");
var AUTO_FETCH_THRESHOLD = 5;
function CardListView(props) {
    var workflowTemplate = props.workflowTemplate, viewTemplate = props.viewTemplate;
    var _a = (0, ControlsProvider_1.useControlsState)(), groups = _a.groups, sorts = _a.sorts, filters = _a.filters;
    var _b = (0, react_1.useState)({}), entityGroupState = _b[0], setEntityGroupState = _b[1];
    var hasGroups = !(0, lodash_1.isEmpty)(groups) && groups.some(function (g) { return !(0, lodash_1.isEmpty)(g); });
    var hasSorts = !(0, lodash_1.isEmpty)(sorts);
    var hasFilters = !(0, lodash_1.isEmpty)(filters);
    var isBoard = viewTemplate.subtype === 'BOARD';
    var isDragSortEnabled = !!viewTemplate.customSortingEnabled && !hasSorts;
    if (!hasGroups) {
        return (react_1.default.createElement(GroupProvider_1.GroupProvider, { group: null },
            react_1.default.createElement(UngroupedCardList, __assign({}, props, { isBoard: isBoard, hasFilters: hasFilters }))));
    }
    var isWeb = react_native_1.Platform.OS === 'web';
    var contentProps = __assign(__assign({}, props), { groups: groups, isBoard: isBoard, entityGroupState: entityGroupState, setEntityGroupState: setEntityGroupState });
    if (isBoard && isWeb) {
        return (react_1.default.createElement(DraggableContextProvider_1.DraggableContextProvider, { workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, setEntityGroupState: setEntityGroupState, isDragSortEnabled: isDragSortEnabled },
            react_1.default.createElement(CardListViewContent, __assign({}, contentProps))));
    }
    return react_1.default.createElement(CardListViewContent, __assign({}, contentProps));
}
function CardListViewContent(_a) {
    var groups = _a.groups, isBoard = _a.isBoard, props = __rest(_a, ["groups", "isBoard"]);
    var _b = (0, react_1.useState)(false), isRefreshing = _b[0], setIsRefreshing = _b[1];
    var _c = (0, react_1.useState)(0), refetchTrigger = _c[0], setRefetchTrigger = _c[1];
    var onRefresh = (0, react_1.useCallback)(function () {
        setIsRefreshing(true);
        setRefetchTrigger(function (prev) { return prev + 1; });
        setTimeout(function () {
            setIsRefreshing(false);
        }, 1000);
    }, []);
    return (react_1.default.createElement(react_native_1.FlatList, { data: groups, keyExtractor: getGroupData_1.getGroupKey, horizontal: isBoard, contentContainerStyle: isBoard ? viewStyles.board : viewStyles.list, refreshControl: react_1.default.createElement(react_native_1.RefreshControl, { refreshing: isRefreshing, onRefresh: onRefresh }), renderItem: function (_a) {
            var group = _a.item;
            return (react_1.default.createElement(CardGroup, __assign({ group: group, refetchTrigger: refetchTrigger, isBoard: isBoard }, props)));
        } }));
}
function CardGroup(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, baseNavigationPath = _a.baseNavigationPath, group = _a.group, refetchTrigger = _a.refetchTrigger, workspaceId = _a.workspaceId, isBoard = _a.isBoard, entityGroupState = _a.entityGroupState, setEntityGroupState = _a.setEntityGroupState;
    return (react_1.default.createElement(GroupProvider_1.GroupProvider, { group: group },
        react_1.default.createElement(CardGroupContent, { workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, baseNavigationPath: baseNavigationPath, group: group, workspaceId: workspaceId, refetchTrigger: refetchTrigger, isBoard: isBoard, entityGroupState: entityGroupState, setEntityGroupState: setEntityGroupState })));
}
function CardGroupContent(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, workspaceId = _a.workspaceId, baseNavigationPath = _a.baseNavigationPath, group = _a.group, refetchTrigger = _a.refetchTrigger, isBoard = _a.isBoard, entityGroupState = _a.entityGroupState, setEntityGroupState = _a.setEntityGroupState;
    var _b = (0, DraggableContext_1.useDraggableContext)(), isDragging = _b.isDragging, activeCards = _b.activeCards, activeGroupId = _b.activeGroupId;
    var _c = (0, CardListViewHooks_1.useCardListView)(workflowTemplate.id, viewTemplate.id, workspaceId, baseNavigationPath), canUpdateCard = _c.canUpdateCard, entities = _c.entities, openEntity = _c.openEntity, entityVariables = _c.entityVariables, hasMoreCards = _c.hasMoreCards, totalCardCount = _c.totalCardCount, fetchMore = _c.fetchMore, isMoreCardsLoading = _c.isMoreCardsLoading, isCardsLoading = _c.isCardsLoading, refetch = _c.refetch;
    var groupData = (0, getGroupData_1.getGroupData)(group);
    var groupComponentId = (0, getGroupData_1.getGroupComponentId)(group);
    var groupId = (0, getGroupData_1.getGroupId)(group);
    (0, react_1.useEffect)(function () {
        if (!(0, lodash_1.isNil)(groupComponentId)) {
            setEntityGroupState(function (prev) {
                var _a;
                return __assign(__assign({}, prev), (_a = {}, _a[groupId] = { entities: entities, entityVariables: entityVariables, groupData: groupData, groupComponentId: groupComponentId, groupId: groupId }, _a));
            });
        }
    }, [
        setEntityGroupState,
        groupId,
        entities,
        totalCardCount,
        entityVariables,
        groupData,
        groupComponentId,
    ]);
    (0, react_1.useEffect)(function () {
        // as the user drags cards out of the column, fetch more cards if there are
        // more to fetch, so they don't see the empty state until the column is
        // really empty
        if (entities.length < AUTO_FETCH_THRESHOLD &&
            hasMoreCards &&
            !isDragging &&
            !isMoreCardsLoading) {
            fetchMore();
        }
    }, [entities.length, hasMoreCards, fetchMore, isDragging, isMoreCardsLoading]);
    var items = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = entityGroupState[groupId]) === null || _a === void 0 ? void 0 : _a.entities) !== null && _b !== void 0 ? _b : []; }, [entityGroupState, groupId]);
    // create list of draggable ids for the sortable context
    var draggableIds = (0, react_1.useMemo)(function () {
        return items.map(function (item) {
            return (0, DraggableContextProvider_1.getDraggableId)({ groupId: groupId, entityId: item.id, activeGroupId: activeGroupId, activeCards: activeCards });
        });
    }, [items, activeCards, activeGroupId, groupId]);
    (0, react_1.useEffect)(function () {
        if (refetchTrigger === 0) {
            return;
        }
        refetch();
    }, [refetchTrigger, refetch]);
    var isEmptyState = (0, lodash_1.isEmpty)(entities) && !isCardsLoading;
    var testID = getTestId({ isCardsLoading: isCardsLoading, isBoard: isBoard, isEmptyState: isEmptyState });
    return (react_1.default.createElement(sortable_1.SortableContext, { items: draggableIds, strategy: sortable_1.verticalListSortingStrategy },
        react_1.default.createElement(GroupContainer_1.GroupContainer, { workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, baseNavigationPath: baseNavigationPath, totalCardCount: totalCardCount, wrapperStyles: isBoard ? boardGroupStyles : listGroupStyles, group: group, groupId: groupId, collapseIfEmpty: !isBoard },
            react_1.default.createElement(CardList_1.CardList, { workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, canUpdateCard: canUpdateCard, entities: items, openEntity: openEntity, hasMore: hasMoreCards, showMore: fetchMore, isMoreLoading: isMoreCardsLoading, isCardsLoading: isCardsLoading, contentContainerStyle: isBoard ? cardListStyles.board : cardListStyles.list, isGroup: true, groupId: groupId, testID: testID }))));
}
function UngroupedCardList(_a) {
    var workflowTemplate = _a.workflowTemplate, viewTemplate = _a.viewTemplate, workspaceId = _a.workspaceId, baseNavigationPath = _a.baseNavigationPath, mode = _a.mode, isBoard = _a.isBoard, hasFilters = _a.hasFilters;
    var _b = (0, CardListViewHooks_1.useCardListView)(workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.id, workspaceId, baseNavigationPath), canUpdateCard = _b.canUpdateCard, entities = _b.entities, openEntity = _b.openEntity, fetchMore = _b.fetchMore, hasMoreCards = _b.hasMoreCards, isMoreCardsLoading = _b.isMoreCardsLoading, isCardsLoading = _b.isCardsLoading, refetch = _b.refetch;
    var isEmptyState = (0, lodash_1.isEmpty)(entities) && !isCardsLoading;
    var testID = getTestId({ isCardsLoading: isCardsLoading, isBoard: isBoard, isEmptyState: isEmptyState });
    var isList = !isBoard;
    var isNarrowBoard = isBoard && mode === 'split-chat';
    if (isEmptyState) {
        return react_1.default.createElement(EmptyUngroupedCardList, { hasFilters: hasFilters, testID: testID });
    }
    return (react_1.default.createElement(react_native_1.View, { style: [
            isList && viewStyles.ungroupedList,
            isBoard && viewStyles.board,
            isNarrowBoard && viewStyles.narrowUngroupedBoard,
        ] },
        react_1.default.createElement(react_native_1.View, { style: [
                isList && listGroupStyles.container,
                isBoard && boardGroupStyles.container,
                isNarrowBoard && boardGroupStyles.narrowUngroupedContainer,
            ] },
            react_1.default.createElement(CardList_1.CardList, { workflowTemplate: workflowTemplate, viewTemplate: viewTemplate, canUpdateCard: canUpdateCard, entities: entities, openEntity: openEntity, showMore: fetchMore, hasMore: hasMoreCards, isMoreLoading: isMoreCardsLoading, isCardsLoading: isCardsLoading, contentContainerStyle: [
                    isList && cardListStyles.list,
                    isBoard && cardListStyles.board,
                    isNarrowBoard && cardListStyles.narrowUngroupedBoard,
                ], isGroup: false, refetch: refetch, testID: testID })),
        isBoard && !isCardsLoading && (react_1.default.createElement(components_1.MBView, { style: [!isNarrowBoard && viewStyles.boardAddGroups] },
            react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: "Apply groups to add columns", description: "Add some groups to create columns for this board." })))));
}
function EmptyUngroupedCardList(_a) {
    var hasFilters = _a.hasFilters, testID = _a.testID;
    var emptyTitle = hasFilters
        ? (0, i18n_1.i18n)('emptyCardList.filtered.title')
        : (0, i18n_1.i18n)('emptyCardList.title');
    var emptyMessage = hasFilters
        ? (0, i18n_1.i18n)('emptyCardList.filtered.description')
        : (0, i18n_1.i18n)('emptyCardList.description');
    return (react_1.default.createElement(components_1.MBView, { paddingBottom: (0, components_1.grid)(0.6), fill: true, testID: testID },
        react_1.default.createElement(emptyState_1.MBCardEmptyState, { title: emptyTitle, message: emptyMessage })));
}
var viewStyles = react_native_1.StyleSheet.create({
    board: {
        paddingHorizontal: (0, components_1.grid)(1),
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        minWidth: (0, components_1.grid)(70),
    },
    narrowUngroupedBoard: {
        flexDirection: 'column',
        minWidth: 0,
        overflow: 'scroll',
    },
    boardAddGroups: {
        flex: 1,
        paddingBottom: (0, components_1.grid)(0.6),
    },
    list: {
        paddingHorizontal: (0, components_1.grid)(1),
        paddingBottom: (0, components_1.grid)(10),
        flexGrow: 1,
    },
    ungroupedList: {
        overflow: 'hidden',
        flex: 1,
    },
});
var listGroupStyles = react_native_1.StyleSheet.create({
    container: {
        overflow: 'hidden',
        flex: 1,
    },
    header: {
        flexGrow: 0,
    },
});
var boardGroupStyles = react_native_1.StyleSheet.create({
    container: {
        maxWidth: (0, components_1.grid)(40),
        paddingHorizontal: 0,
        transition: 'max-width 0.2s ease-in-out',
        overflow: 'hidden',
        flexGrow: 1,
    },
    narrowUngroupedContainer: {
        maxWidth: '100%',
        order: 1,
    },
    header: {
        flexGrow: 0,
        zIndex: 1,
    },
    isCollapsed: {
        maxWidth: (0, components_1.grid)(7),
    },
});
var cardListStyles = react_native_1.StyleSheet.create({
    list: {
        paddingHorizontal: (0, components_1.grid)(1),
        paddingBottom: (0, components_1.grid)(10),
    },
    board: {
        width: (0, components_1.grid)(40),
        paddingBottom: (0, components_1.grid)(10),
    },
    narrowUngroupedBoard: {
        width: '100%',
    },
});
function getTestId(_a) {
    var isCardsLoading = _a.isCardsLoading, isBoard = _a.isBoard, isEmptyState = _a.isEmptyState;
    var type = isBoard ? 'board' : 'list';
    if (isCardsLoading) {
        return "card-".concat(type, "-loading");
    }
    if (isEmptyState) {
        return "card-".concat(type, "-empty-state");
    }
    return "card-".concat(type, "-container");
}
