"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeolocationMapPreview = GeolocationMapPreview;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var row_1 = require("@shared/scenes/chat/input/components/attachment/row");
var styles_1 = require("@shared/styles");
var utils_1 = require("./utils");
function GeolocationMapPreview(_a) {
    var lat = _a.lat, lon = _a.lon, googlePlaceId = _a.googlePlaceId, iconUrl = _a.iconUrl, width = _a.width, height = _a.height, _b = _a.dropShadow, dropShadow = _b === void 0 ? true : _b, onClear = _a.onClear;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var openMap = (0, utils_1.useOpenMap)(lat, lon, googlePlaceId);
    var googleMapsImageUrl = (0, utils_1.useGoogleMapsImageUrl)(lat, lon, iconUrl);
    return (react_1.default.createElement(components_1.MBTouchableOpacity, { style: [dropShadow && styles_1.SharedStyles.shadow, { borderRadius: (0, components_1.grid)(1) }], onPress: openMap },
        react_1.default.createElement(components_1.MBImage, { source: { uri: googleMapsImageUrl }, style: {
                width: width || '100%',
                height: height || (0, components_1.grid)(12),
                backgroundColor: colors.backgroundInactive,
                borderRadius: (0, components_1.grid)(1),
                overflow: 'hidden',
            } }),
        !(0, lodash_1.isNil)(onClear) && react_1.default.createElement(row_1.MBRemoveAttachmentButton, { onPress: onClear })));
}
