"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEntityFormViewTemplate = useEntityFormViewTemplate;
var lodash_1 = require("lodash");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useEntityFormViewTemplate(_a) {
    var _b;
    var workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId, type = _a.type;
    // Further exploration here: https://app.shortcut.com/coast/story/46087/no-code-forms-refine-card-view-template-solution
    var cardViewTemplates = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'CARD',
        isPublic: false,
        skip: !(0, lodash_1.isNil)(viewTemplateId),
    }).viewTemplates;
    var sortedViewTemplates = (0, lodash_1.sortBy)(cardViewTemplates, 'updatedAt');
    var queriedViewTemplate = (0, hooks_1.useViewTemplate)(workflowTemplateId, viewTemplateId).viewTemplate;
    return !(0, lodash_1.isNil)(viewTemplateId)
        ? queriedViewTemplate
        : (_b = sortedViewTemplates.find(function (vt) {
            // not the best way to do this, see SC ticket linked above
            return type === 'CREATE' ? vt.name.startsWith('Create ') : !vt.name.startsWith('Create ');
        })) !== null && _b !== void 0 ? _b : sortedViewTemplates[0];
}
