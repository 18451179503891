"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialWorkflowTemplate = void 0;
exports.initialWorkflowTemplate = {
    name: '',
    requiresName: false,
    components: [],
    archivedComponents: [],
    behavior: null,
    channelDefaultConfiguration: null,
};
