"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBMultiSelect = MBMultiSelect;
// @ts-strict-ignore
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var styles_1 = require("@shared/components/form/styles");
var styles_2 = require("@shared/styles");
var alert_1 = require("@shared/util/alert");
var getTestId_1 = require("@shared/util/getTestId");
var i18n_1 = require("@shared/util/i18n");
var typography_1 = require("../typography");
var ROW_HEIGHT = (0, components_1.grid)(7);
function defaultKeyExtractor(value) {
    return String(value);
}
var alwaysMatch = function () { return true; };
var defaultOnClose = function () { return null; };
function MBMultiSelect(_a) {
    var _this = this;
    var onSearch = _a.onSearch, onChange = _a.onChange, onCreate = _a.onCreate, onEdit = _a.onEdit, renderItem = _a.renderItem, renderArchivedItem = _a.renderArchivedItem, renderCreateNew = _a.renderCreateNew, renderEmptyState = _a.renderEmptyState, values = _a.values, selectedValues = _a.selectedValues, _b = _a.match, match = _b === void 0 ? alwaysMatch : _b, _c = _a.keyExtractor, keyExtractor = _c === void 0 ? defaultKeyExtractor : _c, _d = _a.max, max = _d === void 0 ? 0 : _d, _e = _a.selectAll, selectAll = _e === void 0 ? false : _e, _f = _a.selectAllLabel, selectAllLabel = _f === void 0 ? (0, i18n_1.i18n)('common.selectAll') : _f, _g = _a.errorLabel, errorLabel = _g === void 0 ? (0, i18n_1.i18n)('errors.card.enterName') : _g, renderSelectAll = _a.renderSelectAll, _h = _a.onClose, onClose = _h === void 0 ? defaultOnClose : _h, editMode = _a.editMode, archivedValues = _a.archivedValues, helpText = _a.helpText, header = _a.header, disableSelection = _a.disableSelection, displaySelected = _a.displaySelected, _j = _a.isRequired, isRequired = _j === void 0 ? false : _j, _k = _a.loading, loading = _k === void 0 ? false : _k, _l = _a.hasMore, hasMore = _l === void 0 ? false : _l, loadMore = _a.loadMore;
    var colors = (0, react_1.useContext)(styles_2.ColorThemeContext);
    var _m = (0, react_1.useState)(''), query = _m[0], _setQuery = _m[1];
    var _o = (0, react_1.useState)(null), error = _o[0], setError = _o[1];
    var _p = (0, react_1.useState)(false), creating = _p[0], setCreating = _p[1];
    var setQuery = (0, react_1.useCallback)(function (keyword) {
        _setQuery(keyword);
        onSearch === null || onSearch === void 0 ? void 0 : onSearch(keyword);
    }, [onSearch]);
    var filteredItems = (0, react_1.useMemo)(function () {
        return values === null || values === void 0 ? void 0 : values.filter(function (value) { return match(value, query, false); }).map(function (item) { return ({
            value: item,
            selected: selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.some(function (selectedItem) { return keyExtractor(selectedItem) === keyExtractor(item); }),
            archived: archivedValues === null || archivedValues === void 0 ? void 0 : archivedValues.some(function (archivedItem) { return keyExtractor(archivedItem) === keyExtractor(item); }),
        }); });
    }, [values, match, query, selectedValues, archivedValues, keyExtractor]);
    var nonArchivedValues = (0, react_1.useMemo)(function () {
        return values === null || values === void 0 ? void 0 : values.filter(function (item) {
            return !(archivedValues === null || archivedValues === void 0 ? void 0 : archivedValues.some(function (archivedItem) { return keyExtractor(archivedItem) === keyExtractor(item); }));
        });
    }, [archivedValues, keyExtractor, values]);
    var isAllSelected = (0, react_1.useMemo)(function () {
        return nonArchivedValues.length > 0 && nonArchivedValues.length === (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length);
    }, [nonArchivedValues, selectedValues]);
    var toggleItem = (0, react_1.useCallback)(function (value, component) {
        if (editMode) {
            onEdit(value, component);
            return;
        }
        if (max === 1) {
            if (!isRequired) {
                var newValue = (0, lodash_1.isEqual)(selectedValues, [value]) ? [] : [value];
                onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
            }
            else {
                onChange === null || onChange === void 0 ? void 0 : onChange([value]);
            }
            onClose();
        }
        else if (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.find(function (v) { return keyExtractor(v) === keyExtractor(value); })) {
            if (!isRequired || selectedValues.length > 1) {
                onChange === null || onChange === void 0 ? void 0 : onChange(selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.filter(function (v) { return keyExtractor(v) !== keyExtractor(value); }));
            }
        }
        else if (max === 0 || (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length) < max) {
            onChange === null || onChange === void 0 ? void 0 : onChange(__spreadArray(__spreadArray([], selectedValues, true), [value], false));
        }
    }, [selectedValues, max, keyExtractor, onChange, onClose, editMode, onEdit, isRequired]);
    var toggleSelectAll = (0, react_1.useCallback)(function () {
        var newValues;
        if (isAllSelected) {
            newValues = [];
        }
        else {
            newValues = __spreadArray([], values, true).filter(function (value) { return !(archivedValues === null || archivedValues === void 0 ? void 0 : archivedValues.includes(value)); });
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(newValues);
    }, [isAllSelected, onChange, values, archivedValues]);
    var renderListItem = (0, react_1.useCallback)(function (listItem, index) { return (react_1.default.createElement(components_1.MBHover, { hoverStyle: {
            backgroundColor: styles_2.Colors.v2.backgroundGrayLight,
        }, key: index },
        react_1.default.createElement(components_1.MBTouchableOpacity, { key: keyExtractor(listItem.value), onPress: function (component) { return toggleItem(listItem.value, component); }, disabled: (!editMode && listItem.archived) || disableSelection, style: { paddingHorizontal: (0, components_1.grid)(2) } },
            react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { minHeight: ROW_HEIGHT } },
                react_1.default.createElement(components_1.MBView, { flex: true }, listItem.archived
                    ? renderArchivedItem(listItem.value)
                    : renderItem(listItem.value, index)),
                !editMode && (react_1.default.createElement(components_1.MBView, { style: { minHeight: ROW_HEIGHT }, paddingTop: 0.5 },
                    react_1.default.createElement(components_1.MBSelectableDot, { radio: max === 1, selected: listItem.selected, testID: (0, getTestId_1.getTestId)('card-select-item', index, 'checkbox', listItem.selected ? 'checked' : 'unchecked') }))),
                editMode && (react_1.default.createElement(components_1.MBView, { style: { minHeight: ROW_HEIGHT }, paddingTop: 0.5, centerVertically: true },
                    react_1.default.createElement(components_1.MBIconV2, { name: "arrow-point-right", color: styles_2.Colors.v2.gray30, size: (0, components_1.grid)(3) }))))))); }, [keyExtractor, disableSelection, editMode, renderArchivedItem, renderItem, max, toggleItem]);
    var searchInputRef = (0, react_1.useRef)(null);
    var handleCreateNewPress = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(query.length === 0)) return [3 /*break*/, 1];
                    setError(errorLabel);
                    (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    return [3 /*break*/, 6];
                case 1:
                    if (!onCreate) {
                        return [2 /*return*/];
                    }
                    if (values.find(function (item) { return match(item, query, true); })) {
                        return [2 /*return*/];
                    }
                    setCreating(true);
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, onCreate(query)];
                case 3:
                    _b.sent();
                    setTimeout(function () {
                        setQuery('');
                        searchInputRef.current.focus();
                    }, 1000);
                    return [3 /*break*/, 6];
                case 4:
                    err_1 = _b.sent();
                    alert_1.MBAlert.alert({ message: (0, i18n_1.i18n)('errors.tag.create') });
                    console.error(String(err_1));
                    return [3 /*break*/, 6];
                case 5:
                    setCreating(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [query, errorLabel, onCreate, values, match, setQuery]);
    var _q = (0, react_1.useMemo)(function () { return (0, lodash_1.partition)(filteredItems, function (item) { return !item.archived; }); }, [filteredItems]), activeItems = _q[0], archivedItems = _q[1];
    var onChangeSearchText = (0, react_1.useCallback)(function (updateQuery) {
        setQuery(updateQuery);
        setError(null);
    }, [setQuery, setError]);
    var maxReached = !(0, lodash_1.isNil)(max) && max > 0 && (selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length) >= max ? true : false;
    var isWeb = react_native_1.Platform.OS === 'web';
    return (react_1.default.createElement(components_1.MBView, { fill: true, paddingBottom: 1 },
        react_1.default.createElement(components_1.MBView, { style: styles_1.Styles.whiteBackground, paddingTop: 2, paddingBottom: 1, paddingHorizontal: 2 },
            header,
            isWeb && (react_1.default.createElement(components_1.MBSearchBar, { style: { paddingLeft: (0, components_1.grid)(2) }, forwardRef: searchInputRef, value: query, onChangeText: onChangeSearchText, placeholder: onCreate ? (0, i18n_1.i18n)('common.searchOrCreate') : undefined, returnKeyType: "go", autoFocus: react_native_1.Platform.OS === 'web' })),
            !isWeb && (react_1.default.createElement(components_1.MBTextInput, { autoFocus: true, onChangeText: onChangeSearchText, value: query, placeholder: (0, i18n_1.i18n)('common.searchDotDotDot'), style: { paddingHorizontal: (0, components_1.grid)(4) } })),
            !!error && (react_1.default.createElement(typography_1.MBTextBody2, { color: colors.error.foreground, style: { padding: (0, components_1.grid)(1) } }, error)),
            displaySelected && (react_1.default.createElement(components_1.MBView, { paddingTop: 2, paddingHorizontal: 1, row: true },
                react_1.default.createElement(typography_1.MBTextBody2Bold, null, selectedValues.length + ' '),
                react_1.default.createElement(typography_1.MBTextBody2, null,
                    !maxReached && (0, i18n_1.i18n)('card.blocks.cardsInput.selected.default'),
                    maxReached && (0, i18n_1.i18n)('card.blocks.cardsInput.selected.maxReached'))))),
        react_1.default.createElement(components_1.MBView, { fill: true },
            react_1.default.createElement(react_native_1.ScrollView, { keyboardShouldPersistTaps: "always" },
                loading && (react_1.default.createElement(react_native_1.View, { style: {
                        height: 150,
                        alignItems: 'center',
                        justifyContent: 'center',
                    } },
                    react_1.default.createElement(components_1.MBLoadingIndicator, null))),
                !loading && (0, lodash_1.isEmpty)(activeItems) && (0, lodash_1.isNil)(renderEmptyState) && (react_1.default.createElement(components_1.MBEmptyListPlaceholder, { title: (0, i18n_1.i18n)('common.empty') })),
                !!helpText && (react_1.default.createElement(components_1.MBView, { paddingVertical: 2, paddingHorizontal: 1, center: true },
                    react_1.default.createElement(components_1.MBTextBody1, { style: { textAlign: 'left' } }, helpText))),
                selectAll &&
                    max !== 1 &&
                    query.length === 0 &&
                    filteredItems.length > 0 &&
                    !editMode && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: toggleSelectAll },
                    react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingHorizontal: 2 },
                        (renderSelectAll === null || renderSelectAll === void 0 ? void 0 : renderSelectAll()) || (react_1.default.createElement(components_1.MBView, { flex: true, centerVertically: true, paddingLeft: 2, style: { height: ROW_HEIGHT } },
                            react_1.default.createElement(components_1.MBTextBody1, null, selectAllLabel))),
                        react_1.default.createElement(components_1.MBSelectableDot, { radio: false, selected: isAllSelected })))),
                activeItems.map(renderListItem),
                activeItems.length === 0 && (renderEmptyState === null || renderEmptyState === void 0 ? void 0 : renderEmptyState(query)),
                archivedItems.length > 0 && editMode && (react_1.default.createElement(components_1.MBView, { paddingLeft: 3, paddingVertical: 0.5 },
                    react_1.default.createElement(components_1.MBTextOverlineBold, { color: styles_2.Colors.v2.gray50 }, (0, i18n_1.i18n)('common.archived')))),
                editMode && archivedItems.map(renderListItem),
                hasMore && (react_1.default.createElement(react_native_1.View, { style: { paddingVertical: (0, components_1.grid)(3) } },
                    react_1.default.createElement(components_1.MBButton, { title: (0, i18n_1.i18n)('card.list.showMore'), size: "small", theme: "transparent", bold: false, borderColor: styles_2.Colors.v2.gray10, onPress: loadMore, style: { alignSelf: 'center' } }))))),
        !!renderCreateNew && !!onCreate && !editMode && (react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: handleCreateNewPress, disabled: creating, style: { paddingHorizontal: (0, components_1.grid)(2) } }, renderCreateNew(query, creating)))));
}
