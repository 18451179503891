"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListView = ListView;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var CollectionViewBanner_1 = require("@shared/components/billing/CollectionViewBanner");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var useCanViewCollectionView_1 = require("@shared/data/billing/hooks/useCanViewCollectionView");
var provider_1 = require("@shared/scenes/billing/upgradeModal/provider");
var context_1 = require("@shared/scenes/channel/webLayout/context");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var usePaywallExperiment_1 = require("@shared/util/featureFlags/launchDarkly/hooks/usePaywallExperiment");
var i18n_1 = require("@shared/util/i18n");
var CollectionViewMetrics_1 = require("../collection/CollectionViewMetrics");
var CollectionViewControls_1 = require("../collectionView/components/CollectionViewControls");
var CardListView_1 = require("./components/CardListView");
function ListView(props) {
    var workflowTemplate = props.workflowTemplate, viewTemplate = props.viewTemplate;
    var updateViewTemplate = (0, hooks_1.useUpdateViewTemplate)(workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id);
    var isPaid = (0, useCanViewCollectionView_1.useCanViewCollectionView)();
    var _a = (0, usePaywallExperiment_1.usePaywallExperiment)(), isBannerGroup = _a.isBannerGroup, groupName = _a.groupName;
    var showUpgradeModal = (0, react_1.useContext)(provider_1.UpgradeModalContext).show;
    var mode = (0, react_1.useContext)(context_1.WebLayoutContext).mode;
    (0, usePaywallExperiment_1.useTrackPaywallExperimentView)(groupName);
    var showModal = (0, react_1.useCallback)(function () {
        showUpgradeModal({
            title: (0, i18n_1.i18n)('card.list.upgradeModal.title'),
            description: (0, i18n_1.i18n)('card.list.upgradeModal.description'),
        });
    }, [showUpgradeModal]);
    (0, react_1.useEffect)(function () {
        (0, CollectionViewMetrics_1.trackCollectionViewLoad)(CollectionViewMetrics_1.CollectionViewType.NO_CODE_LIST);
    }, []);
    if ((0, lodash_1.isNil)(workflowTemplate)) {
        return null;
    }
    return (react_1.default.createElement(ControlsProvider_1.ControlsProvider, { workflowTemplateId: workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id, viewTemplate: viewTemplate },
        react_1.default.createElement(react_native_1.View, { style: { height: '100%', width: '100%', backgroundColor: styles_1.Colors.v2.backgroundGrayLight } },
            react_1.default.createElement(CollectionViewControls_1.CollectionViewControls, { viewTemplate: viewTemplate, updateViewTemplate: updateViewTemplate, workflowTemplateId: workflowTemplate.id }),
            !isPaid && isBannerGroup && (react_1.default.createElement(react_native_1.View, { style: { paddingHorizontal: (0, components_1.grid)(2), marginTop: (0, components_1.grid)(1) } },
                react_1.default.createElement(CollectionViewBanner_1.CollectionViewBanner, { onPress: showModal }))),
            react_1.default.createElement(CardListView_1.CardListView, __assign({}, props, { mode: mode })))));
}
