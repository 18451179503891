"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDefaultFields = useDefaultFields;
var lodash_1 = require("lodash");
var react_1 = require("react");
var CardField_1 = require("@shared/data/cards/CardField");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var ComponentOptionDefaults_1 = require("@shared/scenes/workflows/template/ComponentOptionDefaults");
function useDefaultFields(_a) {
    var workflowTemplateId = _a.workflowTemplateId, viewTemplate = _a.viewTemplate, queryFieldOverrides = _a.queryFieldOverrides;
    var workflowTemplate = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId !== null && workflowTemplateId !== void 0 ? workflowTemplateId : undefined).workflowTemplate;
    var allComponents = (workflowTemplate !== null && workflowTemplate !== void 0 ? workflowTemplate : {}).components;
    var components = (0, react_1.useMemo)(function () { var _a; return (_a = allComponents === null || allComponents === void 0 ? void 0 : allComponents.filter(hooks_1.isInputComponent).filter(function (c) { return c.id !== ComponentOptionDefaults_1.STATIC_COMPONENT_IDS.NAME; })) !== null && _a !== void 0 ? _a : []; }, [allComponents]);
    return (0, react_1.useMemo)(function () {
        var fieldsBuilder = {};
        components.forEach(function (component) {
            var _a, _b;
            var fieldOverrides = (_b = (_a = viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.componentsViewOptions) === null || _a === void 0 ? void 0 : _a.find(function (option) {
                return option.componentId === component.id;
            })) === null || _b === void 0 ? void 0 : _b.overrides;
            var builtField = (0, CardField_1.buildCardField)(null, component, {
                personRefs: null,
                workflowEntityRefs: null,
                workflowTemplateRefs: null,
                recurringEntitiesScheduleRefs: null,
            }, getFieldDefault(component, fieldOverrides, queryFieldOverrides));
            if (!(0, lodash_1.isNil)(builtField)) {
                fieldsBuilder[component.id] = builtField;
            }
        });
        return fieldsBuilder;
    }, [components, viewTemplate, queryFieldOverrides]);
}
function getFieldDefault(component, fieldOverrides, queryFieldOverrides) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var queryOverride = !(0, lodash_1.isNil)(component) ? queryFieldOverrides === null || queryFieldOverrides === void 0 ? void 0 : queryFieldOverrides[component.id] : null;
    if (!(0, lodash_1.isNil)(queryOverride)) {
        return queryOverride;
    }
    var hasOverrideDefaults = !(0, lodash_1.isNil)(fieldOverrides) && 'default' in fieldOverrides && !(0, lodash_1.isNil)(fieldOverrides.default);
    var hasComponentDefaults = !(0, lodash_1.isNil)(component) && 'default' in component && !(0, lodash_1.isNil)(component.default);
    var componentToUse = hasOverrideDefaults
        ? fieldOverrides
        : hasComponentDefaults
            ? component
            : null;
    if ((0, lodash_1.isNil)(componentToUse)) {
        return null;
    }
    if ((0, hooks_1.isTextComponent)(componentToUse)) {
        return !(0, lodash_1.isEmpty)((_a = componentToUse.default) === null || _a === void 0 ? void 0 : _a.text) ? (_b = componentToUse.default) === null || _b === void 0 ? void 0 : _b.text : null;
    }
    if ((0, hooks_1.isTagComponent)(componentToUse) || (0, hooks_1.isAuditTagComponent)(componentToUse)) {
        return !(0, lodash_1.isEmpty)((_c = componentToUse.default) === null || _c === void 0 ? void 0 : _c.value) ? (_d = componentToUse.default) === null || _d === void 0 ? void 0 : _d.value : null;
    }
    if ((0, hooks_1.isScheduledAutomationComponent)(componentToUse)) {
        return {
            automationTriggerDurations: (_g = (_f = (_e = componentToUse.default) === null || _e === void 0 ? void 0 : _e.automationTriggerDurations) === null || _f === void 0 ? void 0 : _f.filter(function (v, i) {
                var _a, _b;
                return !(0, lodash_1.isNil)((_a = componentToUse.default) === null || _a === void 0 ? void 0 : _a.automationTriggerDurations)
                    ? ((_b = componentToUse.default) === null || _b === void 0 ? void 0 : _b.automationTriggerDurations.indexOf(v)) === i
                    : false;
            })) !== null && _g !== void 0 ? _g : null,
        };
    }
    if ((0, hooks_1.isRelatedCardComponent)(componentToUse)) {
        return !(0, lodash_1.isEmpty)((_h = componentToUse.default) === null || _h === void 0 ? void 0 : _h.cardIds) ? (_j = componentToUse.default) === null || _j === void 0 ? void 0 : _j.cardIds : null;
    }
    if ((0, hooks_1.isPersonComponent)(componentToUse)) {
        return !(0, lodash_1.isEmpty)((_k = componentToUse.default) === null || _k === void 0 ? void 0 : _k.userIds)
            ? (_l = componentToUse.default) === null || _l === void 0 ? void 0 : _l.userIds
            : !(0, lodash_1.isEmpty)((_m = componentToUse.default) === null || _m === void 0 ? void 0 : _m.token)
                ? (_o = componentToUse.default) === null || _o === void 0 ? void 0 : _o.token
                : null;
    }
    if ((0, hooks_1.isSubformComponent)(componentToUse)) {
        return !(0, lodash_1.isEmpty)((_p = componentToUse.default) === null || _p === void 0 ? void 0 : _p.workflowTemplateId)
            ? (_q = componentToUse.default) === null || _q === void 0 ? void 0 : _q.workflowTemplateId
            : null;
    }
    return null;
}
