"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggableContext = void 0;
exports.useDraggableContext = useDraggableContext;
var react_1 = require("react");
exports.DraggableContext = (0, react_1.createContext)({
    isDragEnabled: false,
    isDragSortEnabled: false,
    isDragging: false,
    activeCards: [],
});
function useDraggableContext() {
    return (0, react_1.useContext)(exports.DraggableContext);
}
